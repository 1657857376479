//import React from 'react';
//import ReactDOM from 'react-dom';
//import App from './App';
//import './styles/main.css';
//import './styles/forms.css';
//import './styles/buttons.css';
//import './styles/layout.css';
//
//
//ReactDOM.render(
//  <React.StrictMode>
//    <App />
//  </React.StrictMode>,
//  document.getElementById('root')
//);

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './styles/main.css';
import './styles/forms.css';
import './styles/buttons.css';
import './styles/layout.css';

const root = document.getElementById('root');
ReactDOM.createRoot(root).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);