import React from 'react';
import { useFormik } from 'formik';
import { Select, MenuItem, ListItemText, ListItemIcon, FormControl} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AWSlogo from '../assets/aws_logo.png';
import AZlogo from '../assets/azure-logo.png';
import GCPlogo from '../assets/gcp_logo.png';
import '../styles/step1.css';

const optionsCloud = [
  { value: 'AWS', label: 'AWS', icon: AWSlogo },
  { value: 'AZURE', label: 'Azure', icon: AZlogo },
  { value: 'GCP', label: 'GCP', icon: GCPlogo },
  // Agrega más opciones aquí
];

const CustomOption = ({ data, ...props }) => (
    <MenuItem {...props} style={{ display: 'flex', alignItems: 'center' }}>
      {data.icon && (
        <ListItemIcon>
          <img src={data.icon} alt={data.label} style={{ width: '30px', height: '30px', marginRight: '10px' }} />
        </ListItemIcon>
      )}
      <ListItemText>{data.label}</ListItemText>
    </MenuItem>
);
  
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 224, // Ajusta esta altura según tus necesidades (4.5 * 48px item height)
      width: 150,
    },
  },
};

const Step3 = ({ formData, updateFormData, nextStep, previousStep }) => {
    const navigate = useNavigate();
    const formik = useFormik({
      initialValues: formData,
      enableReinitialize: true,
      onSubmit: async values => {
        await updateFormData(values);
        nextStep();
      },
    });
  
    const handlePrevious = () => {
        updateFormData(formik.values);
        previousStep();
    };
  
    return (
      <div className="main-container-step1">
        <form className="NewDep-form-container-step1" onSubmit={formik.handleSubmit}>
          <IconButton 
          onClick={() => navigate('/homeNew')} 
          style={{
            position: 'absolute',
            top: '80px',
            right: '20px',
            backgroundColor: '#f0f0f0', // Color tenue
            width: '30px', // Ancho y alto igual para hacerlo redondo
            height: '30px',
            padding: '5px', // Ajuste del padding
            borderRadius: '50%', // Asegura que sea redondo
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            zIndex: 1000 // Asegura que esté encima de otros elementos
            }}
          >
            <CloseIcon style={{ fontSize: '16px', color: '#888' }} /> {/* Tamaño pequeño y color tenue */}
          </IconButton>
          <h2>New Deployment</h2>
          <h4>All fields are required.</h4>
          <FormControl fullWidth>
            <label>
              Cloud Provider:
            </label>
            <Select
              labelId="cloud-provider-select-label"
              id="cloud-provider-select"
              name="deploymentTarget.cloudProvider"
              value={formik.values.deploymentTarget.cloudProvider}
              onChange={formik.handleChange}
              required
              sx={{ backgroundColor: 'white', width: '100%', height: '40px'}}
              MenuProps={MenuProps}
              renderValue={(selected) => {
                const selectedOption = optionsCloud.find(option => option.value === selected);
                return (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {selectedOption && selectedOption.icon && selectedOption.value !== '' && (
                      <img src={selectedOption.icon} alt={selectedOption.label} style={{ width: '30px', height: '30px', marginRight: '10px' }} />
                    )}
                    {selectedOption ? selectedOption.label : '--Select Cloud Provider--'}
                  </div>
                );
              }}
            >
              {optionsCloud.map(option => (
                <CustomOption key={option.value} data={option} value={option.value}/>
              ))}
            </Select>
          </FormControl>
          {formik.values.deploymentTarget.cloudProvider && (
          <>
            {formik.values.deploymentTarget.cloudProvider === 'AWS' && (
              <>
                <FormControl fullWidth>
                    <label>
                      AWS_ACCESS_KEY_ID:
                      <input type="text" required name="deploymentTarget.credentialsAWS.AWS_ACCESS_KEY_ID" onChange={formik.handleChange} value={formik.values.deploymentTarget.credentialsAWS.AWS_ACCESS_KEY_ID} />
                    </label>
                    <label>
                      AWS_SECRET_ACCESS_KEY:
                      <input type="password" required name="deploymentTarget.credentialsAWS.AWS_SECRET_ACCESS_KEY" onChange={formik.handleChange} value={formik.values.deploymentTarget.credentialsAWS.AWS_SECRET_ACCESS_KEY} />
                    </label>
                    <label>
                      AWS_DEFAULT_REGION:
                      <input type="text" required name="deploymentTarget.credentialsAWS.AWS_DEFAULT_REGION" onChange={formik.handleChange} value={formik.values.deploymentTarget.credentialsAWS.AWS_DEFAULT_REGION} />
                    </label>
                </FormControl>
              </>
            )}
            {formik.values.deploymentTarget.cloudProvider === 'AZURE' && (
              <>
                <label >
                  AZURE_SUBSCRIPTION_ID:
                  <input type="text" required name="deploymentTarget.credentialsAzure.AZURE_SUBSCRIPTION_ID" onChange={formik.handleChange} value={formik.values.deploymentTarget.credentialsAzure.AZURE_SUBSCRIPTION_ID} />
                </label>
                <label >
                  AZURE_TENANT_ID:
                  <input type="text" required name="deploymentTarget.credentialsAzure.AZURE_TENANT_ID" onChange={formik.handleChange} value={formik.values.deploymentTarget.credentialsAzure.AZURE_TENANT_ID} />
                </label>
                <label >
                  AZURE_CLIENT_ID:
                  <input type="text" required name="deploymentTarget.credentialsAzure.AZURE_CLIENT_ID" onChange={formik.handleChange} value={formik.values.deploymentTarget.credentialsAzure.AZURE_CLIENT_ID} />
                </label>
                <label >
                  AZURE_CLIENT_SECRET:
                  <input type="password" required name="deploymentTarget.credentialsAzure.AZURE_CLIENT_SECRET" onChange={formik.handleChange} value={formik.values.deploymentTarget.credentialsAzure.AZURE_CLIENT_SECRET} />
                </label>
              </>
            )}
            {formik.values.deploymentTarget.cloudProvider === 'GCP' && (
              <>
                <label >
                  GCP_PROJECT_ID:
                  <input type="text" name="deploymentTarget.credentialsGCP.GCP_PROJECT_ID" onChange={formik.handleChange} value={formik.values.deploymentTarget.credentialsGCP.GCP_PROJECT_ID} />
                </label>
                <label >
                  GCP_CLIENT_EMAIL:
                  <input type="text" name="deploymentTarget.credentialsGCP.GCP_CLIENT_EMAIL" onChange={formik.handleChange} value={formik.values.deploymentTarget.credentialsGCP.GCP_CLIENT_EMAIL} />
                </label>
                <label >
                  GCP_PRIVATE_KEY:
                  <input type="password" name="deploymentTarget.credentialsGCP.GCP_PRIVATE_KEY" onChange={formik.handleChange} value={formik.values.deploymentTarget.credentialsGCP.GCP_PRIVATE_KEY} />
                </label>
                <label >
                  GCP_CREDENTIALS_JSON:
                  <input type="text" required name="deploymentTarget.credentialsGCP.GCP_CREDENTIALS_JSON" onChange={formik.handleChange} value={formik.values.deploymentTarget.credentialsGCP.GCP_CREDENTIALS_JSON} />
                </label>
              </>
            )}
          </>
          )}
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '60px' }}>
            <div>
              <button type="button" onClick={handlePrevious}>Previous</button>
            </div>
            <div>
              <button type="submit">Next</button>
            </div>
          </div>
        </form>
      </div>
    );
  };

  export default Step3;

  /*<div>
            <h4>Current Values:</h4>
            <pre style={{
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
              maxHeight: '100px',
              overflowY: 'scroll',
              padding: '10px',
              border: '1px solid #ccc',
              borderRadius: '4px'
            }}>{JSON.stringify(formik.values, null, 2)}</pre>
          </div>
          
          placeholder="Deployment Name" 

          <label >
                  GCP_PROJECT_ID:
                  <input type="text" required name="deploymentTarget.credentialsGCP.GCP_PROJECT_ID" onChange={formik.handleChange} value={formik.values.deploymentTarget.credentialsGCP.GCP_PROJECT_ID} />
                </label>
                <label >
                  GCP_CLIENT_EMAIL:
                  <input type="text" required name="deploymentTarget.credentialsGCP.GCP_CLIENT_EMAIL" onChange={formik.handleChange} value={formik.values.deploymentTarget.credentialsGCP.GCP_CLIENT_EMAIL} />
                </label>
                <label >
                  GCP_PRIVATE_KEY:
                  <input type="password" required name="deploymentTarget.credentialsGCP.GCP_PRIVATE_KEY" onChange={formik.handleChange} value={formik.values.deploymentTarget.credentialsGCP.GCP_PRIVATE_KEY} />
                </label>
                <label >
                  GCP_CREDENTIALS_JSON:
                  <input type="text" required name="deploymentTarget.credentialsGCP.GCP_CREDENTIALS_JSON" onChange={formik.handleChange} value={formik.values.deploymentTarget.credentialsGCP.GCP_CREDENTIALS_JSON} />
                </label>*/