import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/LogoOctopusV.jpg';
import imageregister from '../assets/image-login6.jpg';
import '../styles/RegisterForm.css';
import ConfirmationRegisterModal from './MessageModal';
import { encryptData, fetchPublicKey} from '../services/cryptoService'; 

function RegisterForm() {
  const [registerInfo, setRegisterInfo] = useState({
    email: '',
    username: '',
    password: '',
  });
  const navigate = useNavigate();
  const [publicKey, setPublicKey] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState('');
  const [messageCompl, setMessageCompl] = useState('');

  // Obtener la clave pública al montar el componente
  useEffect(() => {
    const loadPublicKey = async () => {
      try {
        const key = await fetchPublicKey();
        setPublicKey(key);
      } catch (error) {
        console.error('Error fetching public key:', error);
      }
    };
    loadPublicKey();
  }, []);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setMessage(null);
  };

  const handleConfirmRegister = async () => {
      try {
        handleCloseDialog();
      } catch (error) {
        console.error('Error Login:', error);
      }
  };

  const handleRegisterChange = (e) => {
    const { name, value } = e.target;
    setRegisterInfo({ ...registerInfo, [name]: value });
    //setRegisterInfo({ ...registerInfo, [e.target.name]: e.target.value });
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(registerInfo.email)) {
      //setErrorMessage('Please enter a valid email address.');
      setMessage("Error email:");
      setMessageCompl("Please enter a valid email address.");
      setOpenDialog(true);
      return;
    }

    try {
      // Encriptar los datos antes de enviarlos
      const encryptedEmail = await encryptData(registerInfo.email, publicKey);
      const encryptedUsername = await encryptData(registerInfo.username, publicKey);
      const encryptedPassword = await encryptData(registerInfo.password, publicKey);
      //const response = await axios.post('http://localhost:3000/api/auth/register', registerInfo);
      const response = await axios.post('https://doaas-backend-q52lot4npq-uc.a.run.app/api/auth/register', {
        email: encryptedEmail,
        username: encryptedUsername,
        password: encryptedPassword
      });
      if (response && response.data) {
        console.log('Registration successful:', response.data);
        setMessage("Success:");
        setMessageCompl("Registration successful.");
        setOpenDialog(true);
        navigate('/login');
      } else {
        console.log('No data received in response');
        alert('No data received in response'); 
      }
      
    } catch (error) {
      console.error('Registration error:', error);
      if (error.response && error.response.data) {
        // Acciones específicas con base en la respuesta del error
        console.error('Error details:', error.response.data);
        //alert(error.response.data.message || 'Registration error or User already exists');
        setMessage("Registration error:");
        setMessageCompl("Registration error or User already exists.");
        setOpenDialog(true);
      } else {
        // Manejar errores generales
        console.error('General error:', error.message);
        setMessage("General error:");
        setMessageCompl(error.message);
        setOpenDialog(true);
        //alert(error.message);
      } 
    }
  };

  // Cambiar el fondo cuando el componente se monta
  useEffect(() => {
    document.body.style.backgroundColor = '#ffffff'; // 332c3c'#f8f8f8' Establece tu color de fondo deseado aquí

    // Reestablecer al desmontar el componente
    return () => {
      document.body.style.backgroundColor = ''; // Vuelve al fondo original o a un color predeterminado
    };
  }, []);

  // Verificar si los campos están completos para habilitar/deshabilitar el botón de Register
  const isRegisterDisabled = !registerInfo.email || !registerInfo.username || !registerInfo.password;

  return (
    <div className="register-container">
      <div className="background-image" style={{ backgroundImage: `url(${imageregister})` }}>
        <span className="custom-text1"></span>
      </div>
      <div className="register-form">
        <center>
          <img src={logo} alt="Logo" className="logo" />
        </center>
        <h1 className="custom-h1">Welcome,</h1>
        <h3 className="custom-h3">Create an account</h3>
        <form onSubmit={handleRegisterSubmit} className="register-form-container">
          <label>
            Email:
            <input
              type="text"
              name="email"
              value={registerInfo.email}
              onChange={handleRegisterChange}
              placeholder="Email"
              autoComplete="current-Email"
              required
            />
          </label>
          <label>
            Username:
            <input
              type="text"
              name="username"
              value={registerInfo.username}
              onChange={handleRegisterChange}
              placeholder="username"
              autoComplete="current-username"
              required
            />
          </label>
          <label>
            Password:
            <input
              type="password"
              name="password"
              value={registerInfo.password}
              onChange={handleRegisterChange}
              placeholder="password"
              autoComplete="current-password"
              required
            />
          </label>
          <div className="button-group" style={{justifyContent: 'center', alignItems: 'center'}}>
            <button type="submit" disabled={isRegisterDisabled}>
              Register
            </button>
          </div>
          <ConfirmationRegisterModal
            open={openDialog}
            onClose={handleCloseDialog}
            onConfirm={handleConfirmRegister}
            message={message}
            messageCompl={messageCompl}
          />
        </form>
        <div className="extra-links" style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '20px' }}>
          <div style={{ flex: 1 }}>
            <center>
              <p>
                <span className="custom-text1">Do you have an account? </span><a className="custom-text1" href="#login" onClick={
                  (e) => {
                    e.preventDefault();
                    navigate('/login');
                  }
                }>Sign In</a>
              </p>
            </center>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterForm;