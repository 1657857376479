import { jwtDecode } from 'jwt-decode';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const checkTokenExpiration = () => {
    const token = localStorage.getItem('token');
    if (!token) return false;

    try {
        const { exp } = jwtDecode(token);
        if (Date.now() >= exp * 1000) {
            return false; // El token ha expirado
        }
        return true; // El token es válido
    } catch (error) {
        return false; // El token es inválido
    }
};

const useAuthCheck = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const isTokenValid = checkTokenExpiration();

        if (!isTokenValid) {
            localStorage.removeItem('token'); // Eliminar el token expirado
            navigate('/login'); // Redirigir al login
        }
    }, [navigate]);
};

export default useAuthCheck;