import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Grid, Paper, CssBaseline, Typography, Box, Button, useMediaQuery, useTheme } from '@mui/material';
import NavBar from './NavBar';
import ProjectList from './ProjectList';
//import { deleteProject } from '../services/projectService';
import ConfirmationModal from './ConfirmationModal';
//import MessageModal from './MessageModal';
import EnvironmentGauge from './EnvironmentGauge';
import VulnerabilitiesTable from './VulnerabilitiesTable';
import SonarMetrics from './SonarMetrics';
import '../styles/tooltips.css';

import GOlogo from '../assets/Go-Logo_Blue.png';
import Javalogo from '../assets/java-logo.png';
import JavaScriptlogo from '../assets/JavaScript-logo.png';
import Pythonlogo from '../assets/Python.png';
import Csharplogo from '../assets/Csharp_Logo.png';
import Cpplogo from '../assets/cpp.png'
import Phplogo from '../assets/php.png';
import typescriptlogo from '../assets/typescript_logo.png';
import Nodelogo from '../assets/node-js.png';
import Rubylogo from '../assets/ruby-logo.png';
import Swiftlogo from '../assets/swift_logo.png';
import Kotlinlogo from '../assets/Kotlin_Icon.png';

import AWSlogo from '../assets/aws_logo.png';
import AZlogo from '../assets/azure-logo.png';
import GCPlogo from '../assets/gcp_logo.png';

import GHlogo from '../assets/github_logo.png';
import GLlogo from '../assets/gitlab_logo.png';
import BBlogo from '../assets/Bitbucket_logo.png';
import AzRlogo from '../assets/azure-repos-logo.png';

import AWSEC2logo from '../assets/aws_ec2_logo.png';
import AWSLambdalogo from '../assets/aws_lambda_logo.png';
import AWSEKSlogo from '../assets/aws_eks.png';
import AWSFarlogo from '../assets/aws_fargate.png';
import AWSEBTlogo from '../assets/aws_ebt_logo.png';

import AzAKSlogo from '../assets/az_aks_logo.png';
import AzApSrlogo from '../assets/az_appServ_logo.png';
import AzContlogo from '../assets/az_container_logo.png';
import AzFunclogo from '../assets/az_funct_logo.png';
import AzVMslogo from '../assets/az_vms_logo.png';

import GCPAppEnglogo from '../assets/GCP_AppEngine.png';
import GCPCFunctlogo from '../assets/GCP_CloudFunctions.png';
import GCPCRun from '../assets/GCP_CloudRun.png';
import GCPCompEng from '../assets/GCP_Compute_Engine.png';
import GCPGKE from '../assets/GCP_GKE.png';

const logoMap = {
  programmingLanguage: {
    javascript: JavaScriptlogo,
    python: Pythonlogo,
    java: Javalogo,
    golang: GOlogo,
    nodejs: Nodelogo,
    csharp: Csharplogo,
    cpp: Cpplogo,
    php: Phplogo,
    typescript: typescriptlogo,
    ruby: Rubylogo,
    swift: Swiftlogo,
    kotlin: Kotlinlogo,
  },
  cloudProvider: {
    AWS: AWSlogo,
    AZURE: AZlogo,
    GCP: GCPlogo,
  },
  computecomponent: {
    ec2: AWSEC2logo,
    lambda: AWSLambdalogo,
    eks: AWSEKSlogo,
    fargate: AWSFarlogo,
    elasticBeanstalk: AWSEBTlogo,
    vm: AzVMslogo,
    functions: AzFunclogo,
    aks: AzAKSlogo,
    containerInstances: AzContlogo,
    appService: AzApSrlogo,
    computeEngine: GCPCompEng,
    cloudFunctions: GCPCFunctlogo,
    gke: GCPGKE,
    cloudRun: GCPCRun,
    appEngine: GCPAppEnglogo,
  },
  repositoryProvider: {
    github: GHlogo,
    gitlab: GLlogo,
    bitbucket: BBlogo,
    azure: AzRlogo,
  },
};

const Dashboard = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const selectedProjectName = localStorage.getItem('selectedProjectName');
    if (selectedProjectName) {
      fetchProjectDetails(selectedProjectName);
    }
  }, []);

  const environments = [
    { name: 'Develop' },
    { name: 'Quality' },
    { name: 'Production' }
  ];

  const fetchProjectDetails = async (projectName) => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`https://doaas-backend-q52lot4npq-uc.a.run.app/api/projects/details?name=${projectName}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const projectDetails = await response.json();
      //alert(JSON.stringify(projectDetails, null, 2));
      setSelectedProject(projectDetails);
      setLoading(false); // Set loading to false once the data is fetched
    } catch (error) {
      console.error('Error fetching project details:', error);
      setLoading(false); // Set loading to false in case of error
    }
  };

  const handleEdit = (project) => {
    navigate(`/edit-project/${project}`, { state: { project } });
    localStorage.setItem('selectedProjectName', project);
  };

  const handleDeleteClick = (project) => {
    setProjectToDelete(project);
    setOpenDialog(true); // Abre el modal
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setProjectToDelete(null);
  };

  const handleConfirmDelete = async () => {
    /*const isConfirmed = window.confirm(`Are you sure you want to delete the project "${project}"?`);
    if (isConfirmed) {*/
      try {
        await deleteProject(projectToDelete);
        handleCloseDialog();
        localStorage.removeItem('selectedProjectName');
        window.location.reload(); // Refrescar la página después de 5 segundos
      } catch (error) {
        console.error('Error deleting project:', error);
      }
    /*} else {
      console.log('Project deletion was canceled.');
    }*/
  };

  const capitalizeWords = (str) => { //Primera letra mayuscula
    return str
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const deleteProject = async (project) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`https://doaas-backend-q52lot4npq-uc.a.run.app/api/projects/${project}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
  
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  
    return response.json();
  };

  const handleProjectSelect = (projectName) => {
    localStorage.setItem('selectedProjectName', projectName);
    setLoading(true); // Set loading to true when a new project is selected
    fetchProjectDetails(projectName);
    setDrawerOpen(false); // Cierra el drawer al seleccionar un proyecto
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    const selectedProjectName = localStorage.getItem('selectedProjectName');
    if (selectedProjectName) {
      fetchProjectDetails(selectedProjectName);
    } else {
      // Fetch initial projects and select the first one by default
      const fetchProjects = async () => {
        const token = localStorage.getItem('token'); // Assuming you have the token stored in localStorage

        try {
          const response = await fetch('https://doaas-backend-q52lot4npq-uc.a.run.app/api/projects/user-projects', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            }
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          if (data.length > 0) {
            if (!selectedProject || selectedProject.project_name !== data[0].project_name) { // Solo seleccionar el primer proyecto si no hay uno seleccionado
              fetchProjectDetails(data[0].project_name); // Select the first project by default
              localStorage.setItem('selectedProjectName', data[0].project_name);
          }
      } else {
            setLoading(false); // Set loading to false if no projects are found
          }
        } catch (error) {
          console.error('Error fetching projects:', error);
          setLoading(false); // Set loading to false in case of error
        }
      };
      fetchProjects();
    }
  }, []);
//
  return (
    <div style={{ display: 'flex', height: '100vh', flexDirection: 'column' }}>
      <CssBaseline />
      {/* Modal para mostrar errores 
      <MessageModal
        open={isErrorModalOpen}
        message={errorMessage}  // Mensaje de error genérico
        messageCompl={errorDetails}  // Detalles del error
        onClose={handleCloseErrorModal}
      />*/}
      <div style={{ display: 'flex', flex: 1, overflow: 'hidden', maxHeight: '70px' }}>
        <NavBar onSelectProject={handleProjectSelect} open={drawerOpen} handleDrawerToggle={handleDrawerToggle} />
      </div>
      <div style={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
        {!isMobile && (
          <ProjectList onSelectProject={handleProjectSelect} open={drawerOpen} onClose={handleDrawerToggle}/>
        )}
        <main style={{ flex: 1, padding: isMobile ? '15px' : '30px', overflow: 'auto' }}>
          {loading ? (
            <div style={{ textAlign: 'center' }}>
              <Typography variant="body1">Loading...</Typography>
            </div>
          ) : !selectedProject ? (
            <div style={{ textAlign: 'center' }}>
              <h1 className="custom-h1">Welcome to Octopus DevOps</h1>
              <h3 className="custom-h3">your Deployment Management Portal.</h3>
              <iframe style={{ width: isMobile ? "100%" : "560px", height: isMobile ? "220px" :"340px" }}
                src="https://www.youtube.com/embed/tH9apP177Vg"
                title="OctopusDevOps"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <Box mt={2}>
                <Button variant="contained" 
                  color="primary" 
                  onClick={() => navigate('/newproject')} 
                  style={{ marginRight: '10px', padding: '10px', fontSize: '16px', width: '150px', maxHeight: '35px' }}>
                  Get Started
                </Button>
              </Box>
            </div>
          ) : (
          <>
            <Typography variant="h6" style={{ marginBottom: '5px', marginTop: '0px' }}>{selectedProject.project_name} Dashboard:</Typography>
            <Box display="flex" justifyContent="left" mb={2}>
              <Button variant="contained" style={{marginBottom: '20px', marginRight: '10px', width: '90px', height: '25px'}} color="primary" onClick={() => handleEdit(selectedProject.project_name)}>
                Edit
              </Button>
              <Button variant="contained" style={{marginBottom: '20px', width: '90px', height: '25px'}} color="secondary" onClick={() => handleDeleteClick(selectedProject.project_name)}>
                Delete
              </Button>
              {/* Modal de Confirmación */}
              <ConfirmationModal
                open={openDialog}
                onClose={handleCloseDialog}
                onConfirm={handleConfirmDelete}
                project={projectToDelete}
              />
            </Box>
            <Grid container spacing={isMobile ? 2 : 3}>
              <Grid item xs={12} md={4}>
                <Paper style={{ padding: 16, minHeight: '70px' }}>
                  <Typography variant="h8">Code Repository:</Typography>
                  <Box display="flex" alignItems="center">
                    <img src={logoMap.repositoryProvider[selectedProject.repository_provider]} alt="Repo Logo" style={{ width: '30px', height: '30px', marginRight: '10px' }} />
                    <Link to={selectedProject.repositoryCredentials.repositoryUrl}>
                      <Typography variant="body1" style={{ wordBreak: 'break-all' }}>{selectedProject.repositoryCredentials.repositoryUrl}</Typography>
                    </Link>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper style={{ padding: 16, minHeight: '70px'}}>
                  <Typography variant="h8">Programming language:</Typography>
                  <Box display="flex" alignItems="center">
                    <img src={logoMap.programmingLanguage[selectedProject.programming_language.toLowerCase()]} alt="Language Logo" style={{ width: '30px', height: '30px', marginRight: '10px' }} />
                    <Typography variant="body1">{capitalizeWords(selectedProject.programming_language)}</Typography>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper style={{ padding: 16, minHeight: '70px' }}>
                  <Typography variant="h8">Cloud Provider & Compute Service:</Typography>
                  <Box display="flex" alignItems="center">
                    <img src={logoMap.cloudProvider[selectedProject.cloud_provider]} alt="Cloud Provider Logo" style={{ width: '30px', height: '30px', marginRight: '10px' }} />
                    <Typography variant="body1">{selectedProject.cloud_provider} - &nbsp;</Typography>
                    <img src={logoMap.computecomponent[selectedProject.compute_component]} alt="Compute Component Logo" style={{ width: '30px', height: '30px', marginRight: '10px' }} />
                    <Typography variant="body1">{selectedProject.compute_component}</Typography>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
            <Grid container spacing={isMobile ? 2 : 3}>
              <Grid item xs={12}>
                <Paper style={{ padding: 16, marginTop: '50px' }}>
                  <Typography variant="h6">Enviroments:</Typography>
                  <Grid container spacing={isMobile ? 2 : 3}>
                    {environments.map((env, index) => (
                      <Grid item xs={12} md={4} key={index}>
                        <EnvironmentGauge environment={env.name} projectName={selectedProject.project_name}/>
                        {/*<EnvironmentStatus environment={env.name} status={env.status} />*/}
                      </Grid>
                    ))}
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper style={{ padding: 16, marginTop: '10px' }}>
                  <Typography variant="h6">Code Review Metrics:</Typography>
                  <SonarMetrics projectName={selectedProject.project_name} />
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper style={{ padding: 16, marginTop: '10px' }}>
                  <Typography variant="h6">Dependency-Check Results:</Typography>
                  <VulnerabilitiesTable projectName={selectedProject.project_name} />
                </Paper>
              </Grid>
            </Grid>
          </>
          )}
        </main>
      </div>
    </div>
  );
};

export default Dashboard;