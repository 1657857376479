import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Typography, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Collapse, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';

const VulnerabilitiesTable = ({ projectName }) => {
  const [vulnerabilities, setVulnerabilities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedRows, setExpandedRows] = useState({});

  useEffect(() => {
    const fetchVulnerabilities = async () => {
        const token = localStorage.getItem('token');
        const projectName = localStorage.getItem('selectedProjectName');
        try {
          const response = await axios.get(`https://doaas-backend-q52lot4npq-uc.a.run.app/api/gcs/vulnerabilities?projectName=${projectName}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Cache-Control': 'no-cache' 
            }
          });
          setVulnerabilities(Array.isArray(response.data.vulnerabilities) ? response.data.vulnerabilities : []);
        } catch (error) {
          console.error("Error fetching vulnerabilities:", error);
          setVulnerabilities([]);
        } finally {
          setLoading(false);
        }
    };

    fetchVulnerabilities();
  }, [projectName]);

  const handleRowClick = (index) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  if (loading) return <Typography>Loading vulnerabilities...</Typography>;
  if (vulnerabilities.length === 0) return <Typography>No vulnerabilities found.</Typography>;

  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
      <TableContainer component={Paper} style={{ width: '100%', borderCollapse: 'collapse', textAlign: 'center', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
        <Table>
          <TableHead style={{ borderBottom: '2px solid #ddd', backgroundColor: '#f5f5f5' }}>
            <TableRow>
              <TableCell>File Name</TableCell>
              <TableCell>Vulnerability ID</TableCell>
              <TableCell>Severity</TableCell>
              <TableCell>Weakness (CWE)</TableCell>
              <TableCell>More Info</TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ borderBottom: '1px solid #ddd' }}>
            {vulnerabilities.map((dep, index) => (
              dep.vulnerabilities.map((vuln, vIndex) => (
                <React.Fragment key={`${index}-${vIndex}`} >
                  <TableRow onClick={() => handleRowClick(`${index}-${vIndex}`)} style={{ cursor: 'pointer' }}>
                    <TableCell>
                      <IconButton aria-label="Expand Row"
                        style={{
                            width: '30px',
                            height: '30px',
                            padding: '5px',
                            borderRadius: '50%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 1000 // Asegura que esté encima de otros elementos
                          }}>
                        <ExpandMoreIcon />
                      </IconButton>
                      {dep.fileName}
                    </TableCell>
                    <TableCell>{vuln.id}</TableCell>
                    <TableCell style={{ color: getSeverityColor(vuln.severity) }}>{vuln.severity}</TableCell>
                    <TableCell>{Array.isArray(vuln.weakness) ? vuln.weakness.join(", ") : vuln.weakness}</TableCell>
                    <TableCell>
                      <a
                        href={`https://nvd.nist.gov/vuln/detail/${vuln.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }}
                      >
                        <IconButton aria-label="More Info" color="primary"
                          style={{
                            width: '30px',
                            height: '30px',
                            padding: '5px',
                            borderRadius: '50%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 1000 // Asegura que esté encima de otros elementos
                          }}>
                          <InfoIcon />
                        </IconButton>
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                      <Collapse in={expandedRows[`${index}-${vIndex}`]} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                          <Typography variant="body2">
                            <strong>File Path:</strong> {dep.filePath || "N/A"}
                          </Typography>
                          <Typography variant="body2">
                            <strong>Description:</strong> {vuln.description || "N/A"}
                          </Typography>
                          <Typography variant="body2">
                            <strong>References:</strong>
                          </Typography>
                          <ul>
                            {vuln.references && vuln.references.length > 0 ? (
                              vuln.references.map((ref, refIndex) => (
                                <li key={refIndex}>
                                  <a href={ref.url} target="_blank" rel="noopener noreferrer">
                                    {ref.name || ref.url}
                                  </a>
                                </li>
                              ))
                            ) : (
                              <li>No references available</li>
                            )}
                          </ul>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

// Función auxiliar para definir el color de severidad
const getSeverityColor = (severity) => {
    switch (severity) {
      case 'Critical':
        return 'red';
      case 'HIGH':
        return 'orange';
      case 'MEDIUM':
        return 'goldenrod';
      case 'LOW':
        return 'green';
      default:
        return 'black';
    }
  };

export default VulnerabilitiesTable;

//<Typography variant="h6" style={{ padding: '16px', textAlign: 'center' }}>Dependency-Check Results</Typography>

/*<div style={{ width: '80%', marginTop: '10px'}}>
      <table style={{ width: '100%', borderCollapse: 'collapse', textAlign: 'center', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
        <thead>
          <tr style={{ borderBottom: '2px solid #ddd', backgroundColor: '#f5f5f5' }}>
            <th>File Name</th>
            <th>Vulnerability</th>
            <th>Severity</th>
            <th>Weakness</th>
            <th>More Info</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="6" style={{ padding: '20px' }}>
                Loading...
              </td>
            </tr>
          ) : vulnerabilities.length === 0 ? (
            <tr>
              <td colSpan="5" style={{ padding: '20px', color: '#999' }}>
                No vulnerabilities found.
              </td>
            </tr>
          ) : (
            vulnerabilities.map((dep, index) => (
              dep.vulnerabilities.map((vuln, vIndex) => (
                <tr key={`${index}-${vIndex}`} style={{ borderBottom: '1px solid #ddd' }}>
                  <td>{dep.fileName}</td>
                  <td>{vuln.id}</td>
                  <td style={{ color: getSeverityColor(vuln.severity) }}>{vuln.severity}</td>
                  <td>{vuln.weakness}</td>
                  <td style={{ padding: '15px' }}>
                    <a
                      href={`https://nvd.nist.gov/vuln/detail/${vuln.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'none' }}
                    >
                      <IconButton aria-label="More Info" color="primary">
                        <InfoIcon />
                      </IconButton>
                    </a>
                  </td>
                </tr>
              ))
            ))
          )}
        </tbody>
      </table>
    </div>*/