// components/History.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { CssBaseline, Typography, IconButton } from '@mui/material';
import NavBar from './NavBar';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import '../styles/EditProject.css'; // Reutilizar los estilos existentes
import axios from 'axios';

const History = () => {
  const { projectId } = useParams(); // Obtener el ID del proyecto de la URL
  const [deployments, setDeployments] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  // Cargar el historial de despliegues al montar el componente
  useEffect(() => {
    const fetchDeploymentHistory = async () => {
      const token = localStorage.getItem('token');
      const projectName = localStorage.getItem('selectedProjectName');
      try {
        const response = await axios.get(`https://doaas-backend-q52lot4npq-uc.a.run.app/api/deployments/history?name=${projectName}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        //alert(JSON.stringify(response, null, 2));
        if (response.data && response.data.length > 0) {
          setDeployments(response.data);
        } else {
          setDeployments([]); // Si no hay registros, dejar la lista vacía
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching deployment history:', error);
        setLoading(false);
      }
    };
    fetchDeploymentHistory();
  }, [projectId]);

  // Formatear la duración
  const formatDuration = (duration) => {
    if (!duration) return '-';
    
    const hours = duration.hours || 0;
    const minutes = duration.minutes || 0;
    const seconds = duration.seconds || 0;

    return `${hours}h ${minutes}m ${seconds}s`;
  };

  // Formatear la duración
  /*const formatLogLink = (logLink) => {
    if (!logLink) return '-';

    alert(JSON.stringify(logLink, null, 2));
    const bucketName = "response-doaas-files"; // Cambia esto si tu bucket tiene otro nombre
    const gcsBaseUrl = "https://storage.googleapis.com/";
    const downloadUrl = `${gcsBaseUrl}${bucketName}/${logLink}`;

    alert(JSON.stringify(downloadUrl, null, 2));

    return downloadUrl;
  };*/

  const handleDownloadLog = async (logLink) => {
    const token = localStorage.getItem('token');
    //alert(JSON.stringify(logLink, null, 2));
  
    try {
      // Hacer la solicitud al backend para obtener la URL firmada
      const response = await fetch('https://doaas-backend-q52lot4npq-uc.a.run.app/api/gcs/generate-signed-url', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ logLink })  // Enviar el logLink para generar la URL firmada
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch signed URL');
      }
  
      const { signedUrl } = await response.json();
  
      // Redirigir al usuario a la URL firmada (esto permitirá descargar el archivo)
      window.open(signedUrl, '_blank');
    } catch (error) {
      console.error('Error fetching signed URL:', error);
    }
  };  

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <CssBaseline />
      <NavBar />

      <IconButton
        onClick={() => navigate('/homeNew')}
        style={{
          position: 'absolute', // Posiciona el botón de cerrar de forma absoluta
          top: '80px', // Espacio superior
          right: '20px', // Espacio del borde derecho
          backgroundColor: '#f0f0f0',
          width: '30px',
          height: '30px',
          padding: '5px',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000 // Asegura que esté encima de otros elementos
        }}
      >
        <CloseIcon style={{ fontSize: '16px', color: '#888' }} />
      </IconButton>

      {/* Contenedor Principal */}
      <div
        style={{
          flex: 1,
          paddingTop: '80px', // Espacio superior para evitar que el título quede debajo del NavBar
          paddingLeft: '20px',
          paddingRight: '20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {/* Título */}
        <Typography variant="h4" style={{ width: '80%', marginBottom: '20px', textAlign: 'left' }}>
          Deployment History
        </Typography>

        {/* Tabla de historial */}
        <div style={{ width: '80%', marginTop: '10px' }}>
          <table style={{ width: '100%', borderCollapse: 'collapse', textAlign: 'center', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
            <thead>
              <tr style={{ borderBottom: '2px solid #ddd', backgroundColor: '#f5f5f5' }}>
                <th>Date Start</th>
                <th>Date End</th>
                <th>Duration</th>
                <th style={{ padding: '15px' }}>Environment</th>
                <th style={{ padding: '15px' }}>Status</th>
                <th style={{ padding: '15px' }}>Log</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="6" style={{ padding: '20px' }}>
                    Loading...
                  </td>
                </tr>
              ) : deployments.length === 0 ? (
                <tr>
                  <td colSpan="6" style={{ padding: '20px', color: '#999' }}>
                    No records found.
                  </td>
                </tr>
              ) : (
                deployments.map((deployment, index) => (
                  <tr key={index} style={{ borderBottom: '1px solid #ddd' }}>
                    <td>{new Date(deployment.date_inicio).toLocaleString()}</td>
                    <td>{deployment.date_final ? new Date(deployment.date_final).toLocaleString() : '-'}</td>
                    <td>{formatDuration(deployment.duration)}</td>
                    <td>{deployment.environment}</td>
                    <td style={{ color: deployment.status === 'Success' ? 'green' : 'red' }}>
                      {deployment.status}
                    </td>
                    <td style={{ padding: '15px' }}>
                    {/*<a href={deployment.logLink} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}> 
                      <IconButton aria-label="Download Log" color="primary">*/}
                      {deployment.log_link ? (
                        <IconButton
                          aria-label="Download Log"
                          color="primary"
                          onClick={() => handleDownloadLog(deployment.log_link)}  // Descargar usando la URL firmada
                          style={{
                            width: '30px',
                            height: '30px',
                            padding: '5px',
                            borderRadius: '50%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 1000 // Asegura que esté encima de otros elementos
                          }}
                        >
                          <DownloadIcon />
                        </IconButton>
                      ) : (
                        <span>No log available</span> // Mostrar un mensaje si no hay logLink disponible
                      )}
                      {/*</a>*/}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default History;
