import React, { useState, useEffect }  from 'react';
import { CssBaseline } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import logo from '../assets/LogoOctopusV.jpg';
import ConfirmationForgotPassModal from './MessageModal';
import { encryptData, fetchPublicKey} from '../services/cryptoService';

const ForgotPassword = () => {
  const [publicKey, setPublicKey] = useState(null);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [messageCompl, setMessageCompl] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  // Obtener la clave pública al montar el componente
  useEffect(() => {
    const loadPublicKey = async () => {
      try {
        const key = await fetchPublicKey();
        setPublicKey(key);
      } catch (error) {
        console.error('Error fetching public key:', error);
      }
    };
    loadPublicKey();
  }, []);

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setMessage(null);
  };

  const handleConfirmForgotPass = async () => {
      try {
        handleCloseDialog();
        navigate('/login');
      } catch (error) {
        console.error('Error ForgotPassword:', error);
      }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const encryptedemail = await encryptData(email, publicKey);
      const payload = JSON.stringify(encryptedemail); 
      const response = await fetch('https://doaas-backend-q52lot4npq-uc.a.run.app/api/auth/forgot-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: payload,
        //body: JSON.stringify({ encryptedemail }),
      });

      if (response.ok) {
        setMessage('If the email exists in our database, you will receive a link to reset your password.');
        setMessageCompl('Please check your registered email.');
        setOpenDialog(true);
        //navigate('/login');
      } else {
        setMessage('Error sending the email.');
        setMessageCompl('Please try again.');
        setOpenDialog(true);
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('Error sending the email.');
      setMessageCompl('Please try again.');
      setOpenDialog(true);
    }
  };

  return (
    <div style={{ display: 'flex', height: '100vh', flexDirection: 'column' }}>
    <CssBaseline />
    <div className="main-container-step1">
      <form className="NewDep-form-container-step1" onSubmit={handleSubmit}>
        <IconButton 
          onClick={() => navigate('/login')} 
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            backgroundColor: '#f0f0f0', // Color tenue
            width: '30px', // Ancho y alto igual para hacerlo redondo
            height: '30px',
            padding: '5px', // Ajuste del padding
            borderRadius: '50%', // Asegura que sea redondo
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center'
            }}
        >
          <CloseIcon style={{ fontSize: '16px', color: '#888' }} /> {/* Tamaño pequeño y color tenue */}
        </IconButton>
        <center style={{marginTop: '-100px'}}>
          <img src={logo} alt="Logo" className="logo" />
        </center>
        <h2>Forgot Password</h2>
        <div>
          <label>Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            placeholder="your-email@domain.com"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <ConfirmationForgotPassModal
          open={openDialog}
          onClose={handleCloseDialog}
          onConfirm={handleConfirmForgotPass}
          message={message}
          messageCompl={messageCompl}
        />
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px', width: '94.3%', marginLeft: '2.5%' }}>
          <button type="submit" style={{width: '160px', height: '40px'}}>
            Send Email
          </button> 
        </div>
        
      </form>
    </div>
    </div>
  );
};

export default ForgotPassword;

//{message && <p style={{ marginTop: '15px', color: message.includes('Error') ? 'red' : 'green' }}>{message}</p>}