import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import NavBar from './NavBar';

const Profile = () => {
  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://doaas-backend-q52lot4npq-uc.a.run.app/api/auth/profile', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setProfile(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching profile:', error);
        navigate('/login');
      }
    };

    fetchProfile();
  }, [navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ display: 'flex', height: '100vh', flexDirection: 'column' }}>
      <CssBaseline />
      <div style={{ display: 'flex', flex: 1, overflow: 'hidden', maxHeight: '70px' }}>
        <NavBar />
      </div>
      <div className="main-container-step1">
      <form className="NewDep-form-container-step1">
        <IconButton 
          onClick={() => navigate('/homeNew')} 
          style={{
            position: 'absolute',
            top: '80px',
            right: '20px',
            backgroundColor: '#f0f0f0', // Color tenue
            width: '30px', // Ancho y alto igual para hacerlo redondo
            height: '30px',
            padding: '5px', // Ajuste del padding
            borderRadius: '50%', // Asegura que sea redondo
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            zIndex: 1000 // Asegura que esté encima de otros elementos
            }}
          >
            <CloseIcon style={{ fontSize: '16px', color: '#888' }} /> {/* Tamaño pequeño y color tenue */}
          </IconButton>
        <h2>Profile</h2>
        <p><strong>Username:</strong> {profile.username}</p>
        <p><strong>Email:</strong> {profile.email}</p>
        </form>
      </div>
    </div>
  );
};

export default Profile;