import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Typography, Menu, MenuItem, Box, Drawer, List, 
  ListItem, ListItemText, Divider, useMediaQuery, useTheme, Collapse} from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
//import SettingsIcon from '@mui/icons-material/Settings';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import logo from '../assets/LogoOctopusH.jpg';
import ProjectList from './ProjectList'; // Importa el ProjectList
import '../styles/dashboard.css';

const NavBar = ({ handleDrawerToggle, onSelectProject }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [projectListOpen, setProjectListOpen] = useState(false); // Estado para controlar la lista de despliegues
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const selectedProjectName = localStorage.getItem('selectedProjectName');

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('selectedProjectName');
    navigate('/login');
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setProjectListOpen(false);
  };

  const handleProjectSelect = (projectName) => {
    if (onSelectProject) {
      onSelectProject(projectName);
    }
    handleDrawerClose(); // Cerrar el Drawer al seleccionar un proyecto en mobile
  };

  const toggleProjectList = (event) => {
    event.stopPropagation(); // Evita que el evento de clic se propague y cierre el drawer
    setProjectListOpen(!projectListOpen); // Alternar el estado de apertura de la lista de despliegues
    
  };

  /*const handleEdit = (project) => {
    navigate(`/edit-secrets/${project}`, { state: { project } });
    localStorage.setItem('selectedProjectName', project);
  };*/

  const menuItems = [
    { text: 'New Deployment', link: '/newproject' },
    { text: 'Secrets', link: '/homeNew' },
  ];
  //<ProjectList onSelectProject={handleProjectSelect} open={drawerOpen} onClose={handleDrawerClose} />
  const drawer = (
    <Box onClick={handleDrawerClose} sx={{ textAlign: 'center', backgroundColor: '#fff', height: '100%' }}>
      <List>
        {menuItems.map((item, index) => (
          <ListItem button component={Link} to={item.link} key={index} sx={{ color: 'black', textAlign: 'left', paddingLeft: '10px'}}>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
        <Divider />
        <ListItem button onClick={toggleProjectList}>
          <ListItemText primary="Deployments List" />
          {projectListOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={projectListOpen} timeout="auto" unmountOnExit>
          <ProjectList onSelectProject={handleProjectSelect} open={drawerOpen} onClose={handleDrawerToggle} />
        </Collapse>
        {/*<Typography sx={{ margin: '10px 0', textAlign: 'left', paddingLeft: '10px' }}>
          Deployments List
        </Typography>
        <ProjectList onSelectProject={handleProjectSelect} open={drawerOpen} onClose={handleDrawerToggle} />*/}
      </List>
    </Box>
  );

  return (
    <AppBar id="myAppBar" position="fixed" className="appBar" style={{ paddingRight: 20, backgroundColor: '#fff', color: 'black'  }} >
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            edge="start"
            aria-label="menu"
            //onClick={handleDrawerOpen}
            onClick={isMobile ? handleDrawerOpen : handleDrawerToggle}
            //onClick={handleDrawerToggle}
            color="inherit" 
            component="div" 
            style={{ flexGrow: 1, marginRight: 5, marginLeft: -20, marginBottom: 20 }}
          >
            <MenuIcon />
          </IconButton>
          <Link to="/homeNew">
            <img src={logo} alt="Logo" className="logoAppBar" />
          </Link>
          {!isMobile && (
            <>
            <Link to="/newproject">
              <Typography variant="h8" color= 'black' component="div" sx={{ flexGrow: 1 }} 
                style={{ marginRight: 30, marginBottom: 25 }}>
                New Deployment
              </Typography>
            </Link>
            <Link to={`/edit-secrets/${selectedProjectName}`}>
              <Typography variant="h8" color="black" component="div" sx={{ flexGrow: 1 }} style={{ marginRight: 30, marginBottom: 25 }}>
                Secrets
              </Typography>
            </Link>
            <Link to={`/project/${selectedProjectName}/history`}>
            <Typography variant="h8" color= 'black' component="div" sx={{ flexGrow: 1 }} 
              style={{ marginRight: 30, marginBottom: 25 }}>
              History
            </Typography>
            </Link>
            {/*<Link to="/homeNew">
            <Typography variant="h8" color= 'black' component="div" sx={{ flexGrow: 1 }} 
              style={{ marginBottom: 25 }}>
              Enlace Login 2
            </Typography>
            </Link>*/}
          </>
          )}
        </Box>
        <Box sx={{ display: 'flex' }}>
          {/*<IconButton color="inherit" style={{ marginRight: -10, marginBottom: 20 }}>
            <SettingsIcon />
          </IconButton>*/}
          <IconButton edge="end" color="inherit" onClick={handleMenu} style={{ right:'-33px', marginBottom: 20 }}>
            <AccountCircle />
          </IconButton>
        </Box>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => navigate('/profile')}>Profile</MenuItem>
          <MenuItem onClick={() => navigate('/change-password')}>Change Password</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </Toolbar>
      <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerClose}>
        {drawer}
      </Drawer>
    </AppBar>
  );
};
//<button className="logout-button" onClick={handleLogout}>Logout</button>
export default NavBar;

/*<Link to="/newproject">
            <Typography variant="h8" color= 'black' component="div" sx={{ flexGrow: 1 }} 
              style={{ marginRight: 30, marginBottom: 25 }}>
              New Deployment
            </Typography>
          </Link>
          <Typography variant="h8" color= 'black' component="div" sx={{ flexGrow: 1 }} 
            style={{ marginRight: 30, marginBottom: 25 }}>
            Secrets
          </Typography>
          <Typography variant="h8" color= 'black' component="div" sx={{ flexGrow: 1 }} 
            style={{ marginRight: 30, marginBottom: 25 }}>
            Enlaces adicionales 3
          </Typography>
          <Typography variant="h8" color= 'black' component="div" sx={{ flexGrow: 1 }} 
            style={{ marginBottom: 25 }}>
            Enlace Ad 4
          </Typography>*/

/*<>
              {menuItems.map((item, index) => (
                <Link to={item.link} key={index}>
                  <Typography
                    //variant="h8"
                    variant="body1"
                    color="black"
                    component="div"
                    sx={{ flexGrow: 1, marginRight: 30, marginBottom: 25 }}
                  >
                    {item.text}
                  </Typography>
                </Link>
              ))}
            </>*/