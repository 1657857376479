import React, { useState, useEffect }  from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import logo from '../assets/LogoOctopusV.jpg';
import ConfirmationResetPassModal from './MessageModal';
import { encryptData, fetchPublicKey} from '../services/cryptoService';

const ResetPassword = () => {
  const [publicKey, setPublicKey] = useState(null);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState('');
  const [messageCompl, setMessageCompl] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setMessage(null);
  };

  // Obtener la clave pública al montar el componente
  useEffect(() => {
    const loadPublicKey = async () => {
      try {
        const key = await fetchPublicKey();
        setPublicKey(key);
      } catch (error) {
        console.error('Error fetching public key:', error);
      }
    };
    loadPublicKey();
  }, []);

  const handleConfirmResetPass = async () => {
      try {
        handleCloseDialog();
        if (password === confirmPassword) {
        navigate('/login');
        }
      } catch (error) {
        console.error('Error ResetPassword:', error);
      }
  };

  const handleSubmit = async (e) => {

    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Passwords do not match");
      setMessageCompl("Please check.");
      setOpenDialog(true);
      return;
    }
    
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    const encryptedpassword = await encryptData(password, publicKey);
    const bodyData = JSON.stringify({ token, encryptedpassword})

    const response = await fetch(`https://doaas-backend-q52lot4npq-uc.a.run.app/api/auth/reset-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: bodyData
    });

    if (response.ok) {
      setMessage("Success:");
      setMessageCompl("Password changed successfully");
      setOpenDialog(true);
    } else {
      setMessage('Error:');
      setMessageCompl("Error changing password");
      setOpenDialog(true);

    }
  };

  return (
    <div style={{ display: 'flex', height: '100vh', flexDirection: 'column' }}>
      <CssBaseline />
      <div className="main-container-step1">
        <form className="NewDep-form-container-step1" onSubmit={handleSubmit}>
          <center style={{marginTop: '-100px'}}>
            <img src={logo} alt="Logo" className="logo" />
          </center>
          <h2>Reset Password</h2>
          <div>
            <label>New Password:</label>
            <input
              type="password"
              value={password}
              placeholder="New Password"
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Confirm New Password:</label>
            <input
              type="password"
              value={confirmPassword}
              placeholder="Confirm New Password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <ConfirmationResetPassModal
            open={openDialog}
            onClose={handleCloseDialog}
            onConfirm={handleConfirmResetPass}
            message={message}
            messageCompl={messageCompl}
          />
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px', width: '94.3%', marginLeft: '2.5%' }}>
            <button type="submit" style={{width: '160px', height: '40px'}}>Reset Password</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;