// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyC0xWEY6oM85ea3vLT1LNIyJQIX-z9LiTE",
    authDomain: "doaas-project.firebaseapp.com",
    projectId: "doaas-project",
    storageBucket: "doaas-project.appspot.com",
    messagingSenderId: "235842347934",
    appId: "1:235842347934:web:06b29b19835e4f483cac8f"
};

// Inicializa Firebase
const app = initializeApp(firebaseConfig);

// Inicializa Firestore
const db = getFirestore(app);

export { db };