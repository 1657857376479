import React, { useState, useEffect } from 'react';
import { useNavigate,  Link } from 'react-router-dom'; //useParams,
import { CssBaseline, Typography, Grid, useMediaQuery, useTheme } from '@mui/material';
import NavBar from './NavBar';
import '../styles/EditProject.css';
import ConfirmationSecretModal from './MessageModal';
import { encryptData, fetchPublicKey} from '../services/cryptoService';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import GOlogo from '../assets/Go-Logo_Blue.png';
import Javalogo from '../assets/java-logo.png';
import JavaScriptlogo from '../assets/JavaScript-logo.png';
import Pythonlogo from '../assets/Python.png';
import Csharplogo from '../assets/Csharp_Logo.png';
import Cpplogo from '../assets/cpp.png'
import Phplogo from '../assets/php.png';
import typescriptlogo from '../assets/typescript_logo.png';
import Nodelogo from '../assets/node-js.png';
import Rubylogo from '../assets/ruby-logo.png';
import Swiftlogo from '../assets/swift_logo.png';
import Kotlinlogo from '../assets/Kotlin_Icon.png';

import AWSlogo from '../assets/aws_logo.png';
import AZlogo from '../assets/azure-logo.png';
import GCPlogo from '../assets/gcp_logo.png';

import GHlogo from '../assets/github_logo.png';
import GLlogo from '../assets/gitlab_logo.png';
import BBlogo from '../assets/Bitbucket_logo.png';
import AzRlogo from '../assets/azure-repos-logo.png';

const logoMap = {
  programmingLanguage: {
    javascript: JavaScriptlogo,
    python: Pythonlogo,
    java: Javalogo,
    golang: GOlogo,
    nodejs: Nodelogo,
    csharp: Csharplogo,
    cpp: Cpplogo,
    php: Phplogo,
    typescript: typescriptlogo,
    ruby: Rubylogo,
    swift: Swiftlogo,
    kotlin: Kotlinlogo,
  },
    cloudProvider: {
      AWS: AWSlogo,
      AZURE: AZlogo,
      GCP: GCPlogo,
    },
    repositoryProvider: {
      github: GHlogo,
      gitlab: GLlogo,
      bitbucket: BBlogo,
      azure: AzRlogo,
    },
  };

const capitalizeWords = (str) => { //Primera letra mayuscula
  return str
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

/*const toUpperCase = (str) => {//Todas las letras mayusculas
    if (typeof str !== 'string') {
      throw new TypeError('El argumento debe ser una cadena de texto.');
    }
    return str.toUpperCase();
};*/

const EditSecret = () => {
  const [projectData, setProjectData] = useState({
    repositoryCredentials: {
      username: '',
      password: '',
    },
    cloudCredentials: {
      AWS_ACCESS_KEY_ID: '',
      AWS_SECRET_ACCESS_KEY: '',
      AWS_DEFAULT_REGION: '',
      AZURE_SUBSCRIPTION_ID: '',
      AZURE_TENANT_ID: '',
      AZURE_CLIENT_ID: '',
      AZURE_CLIENT_SECRET: '',
      GCP_PROJECT_ID: '',
      GCP_CLIENT_EMAIL: '',
      GCP_PRIVATE_KEY: '',
    }
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [publicKey, setPublicKey] = useState(null);
  //const { id } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [message, setMessage] = useState('');
  const [messageCompl, setMessageCompl] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  //const location = useLocation(); useLocation, 

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setMessage(null);
  };

  const handleConfirmSecret = async () => {
      try {
        handleCloseDialog()
        navigate('/homeNew');
      } catch (error) {
        console.error('Error UpdateSecret:', error);
      }
  };

  const getProjectDetails = async (projectName) => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`https://doaas-backend-q52lot4npq-uc.a.run.app/api/projects/details?name=${projectName}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const projectDetails = await response.json();
      //alert(JSON.stringify(projectDetails, null, 2));
      setProjectData(projectDetails);
      setLoading(false); // Set loading to false once the data is fetched
    } catch (error) {
      console.error('Error fetching project details:', error);
      setLoading(false); // Set loading to false in case of error
    }
  };

  // Obtener la clave pública al montar el componente
  useEffect(() => {
    const loadPublicKey = async () => {
      try {
        const key = await fetchPublicKey();
        setPublicKey(key);
      } catch (error) {
        console.error('Error fetching public key:', error);
      }
    };
    loadPublicKey();
  }, []);

  const updateProject = async (projectName, projectData, type) => {
    const token = localStorage.getItem('token');
    // Mostrar projectData
    //alert('Project Data:\n' + JSON.stringify(projectData, null, 2));
    // Crear el body del PUT
    const bodyData = JSON.stringify({
      repositoryCode: {
        credentials: {
          username: projectData.repositoryCredentials.username,
          password: projectData.repositoryCredentials.password
        }
      },
      cloudProvider: projectData.cloud_provider,
      credentialsAWS: {
        AWS_ACCESS_KEY_ID: projectData.cloudCredentials.AWS_ACCESS_KEY_ID,
        AWS_SECRET_ACCESS_KEY: projectData.cloudCredentials.AWS_SECRET_ACCESS_KEY,
        AWS_DEFAULT_REGION: projectData.cloudCredentials.AWS_DEFAULT_REGION
      },
      credentialsAzure: {
        AZURE_SUBSCRIPTION_ID: projectData.cloudCredentials.AZURE_SUBSCRIPTION_ID,
        AZURE_TENANT_ID: projectData.cloudCredentials.AZURE_TENANT_ID,
        AZURE_CLIENT_ID: projectData.cloudCredentials.AZURE_CLIENT_ID,
        AZURE_CLIENT_SECRET: projectData.cloudCredentials.AZURE_CLIENT_SECRET
      },
      credentialsGCP: {
        GCP_PROJECT_ID: projectData.cloudCredentials.GCP_PROJECT_ID,
        GCP_CLIENT_EMAIL: projectData.cloudCredentials.GCP_CLIENT_EMAIL,
        GCP_PRIVATE_KEY: projectData.cloudCredentials.GCP_PRIVATE_KEY
      }
    });
    const encryptedData = await encryptData(bodyData, publicKey);
    const payload = JSON.stringify(encryptedData); 
    // Mostrar bodyData que será enviado
    //alert('Body Data:\n' + bodyData);
    const response = await fetch(`https://doaas-backend-q52lot4npq-uc.a.run.app/api/projects?name=${encodeURIComponent(projectName)}&type=${type}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: payload
    });
  
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  };

  useEffect(() => {
    const selectedProjectName = localStorage.getItem('selectedProjectName');
    const fetchProjectDetails = async () => {
      getProjectDetails(selectedProjectName);
    };
    fetchProjectDetails();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProjectData(prevData => ({ 
      ...prevData, 
      repositoryCredentials: {
        ...prevData.repositoryCredentials,
        [name]: value,
      },
      cloudCredentials: {
        ...prevData.cloudCredentials,
        [name]: value,
      }
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateProject(projectData.project_name, projectData, 'secrets');
      //alert('Secrets updated successfully');
      setMessage('Success:');
      setMessageCompl('Secrets updated successfully.');
      setOpenDialog(true);
      
    } catch (error) {
      setMessage('Error:');
      setMessageCompl('Error updating project:', error);
      setOpenDialog(true);
      console.error('Error updating project:', error);
    }
  };
//<input type="text" name="username" value={projectData.repositoryCredentials.username} onChange={handleChange} />
  return (
    <div style={{ display: 'flex', height: '100vh', flexDirection: 'column' }}>
      <CssBaseline />
      <div style={{ display: 'flex', flex: 1, overflow: 'hidden', maxHeight: '70px' }}>
        <NavBar />
      </div>
      {loading ? (
        <div style={{ textAlign: 'center' }}>
        <Typography variant="body1">Loading...</Typography>
        </div>
      ) : (
        <div className="main-container-editP">
          <form className="NewDep-form-container-editP" onSubmit={handleSubmit}>
          <IconButton 
          onClick={() => navigate('/homeNew')} 
          style={{
            position: 'absolute',
            top: '80px',
            right: '20px',
            backgroundColor: '#f0f0f0', // Color tenue
            width: '30px', // Ancho y alto igual para hacerlo redondo
            height: '30px',
            padding: '5px', // Ajuste del padding
            borderRadius: '50%', // Asegura que sea redondo
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            zIndex: 1000 // Asegura que esté encima de otros elementos
            }}
          >
            <CloseIcon style={{ fontSize: '16px', color: '#888' }} /> {/* Tamaño pequeño y color tenue */}
          </IconButton>
          <h1>Edit Secrets</h1>
          <label disabled style={{ textAlign: 'center' }}>
              <strong>Deployment Name:</strong> {projectData.project_name}
          </label>
          <label disabled style={{ textAlign: 'center' }}>
              <strong>Programming Language:</strong> &nbsp;
              <img src={logoMap.programmingLanguage[projectData.programming_language]} alt="Language Logo" style={{ width: '20px', height: '20px', marginRight: '10px' }} />
              {capitalizeWords(projectData.programming_language)}
          </label>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={6}>
              <h4><strong>Code Repository:</strong></h4>
              <label style={{ textAlign: 'center' }}>
                  <img src={logoMap.repositoryProvider[projectData.repository_provider]} alt="SCR Logo" style={{ width: '20px', height: '20px', marginRight: '10px' }} />
                  {capitalizeWords(projectData.repository_provider)}
              </label>
              <label>
                  <strong>URL:</strong> &nbsp;
                  <Link to={projectData.repositoryCredentials.repositoryUrl}>{projectData.repositoryCredentials.repositoryUrl}</Link>
              </label>
              <label>
                <strong>Username:</strong>
                
                <input type="text" name="username" value={projectData.repositoryCredentials.username} onChange={handleChange} />
              </label>
              <label>
                <strong>Password:</strong>
                <input type="password" name="password" value={projectData.repositoryCredentials.password} onChange={handleChange}/>
              </label>
            </Grid>
            <Grid item xs={12} sm={6}>
              <h4>Cloud Provider</h4>
              <label style={{ textAlign: 'center' }}>
                <img src={logoMap.cloudProvider[projectData.cloud_provider]} alt="Cloud Logo" style={{ width: '20px', height: '20px', marginRight: '10px' }} />
                {projectData.cloud_provider}
              </label>
              {projectData.cloud_provider === 'AWS' && (
                <div>
                  <label>
                    <strong>AWS_ACCESS_KEY_ID:</strong>
                    <input type="text" name="AWS_ACCESS_KEY_ID" value={projectData.cloudCredentials.AWS_ACCESS_KEY_ID} onChange={handleChange} />
                  </label>
                  <label>
                    <strong>AWS_SECRET_ACCESS_KEY:</strong>
                    <input type="password" name="AWS_SECRET_ACCESS_KEY" value={ projectData.cloudCredentials.AWS_SECRET_ACCESS_KEY} onChange={handleChange}/>
                  </label>
                  <label>
                    <strong>AWS_DEFAULT_REGION:</strong>
                    <input type="text" name="AWS_DEFAULT_REGION" value={projectData.cloudCredentials.AWS_DEFAULT_REGION} onChange={handleChange} />
                  </label>
                </div>
              )}
              {projectData.cloud_provider === 'AZURE' && (
                <div>
                  <label>
                    <strong>AZURE_SUBSCRIPTION_ID:</strong>
                    <input type="text" name="AZURE_SUBSCRIPTION_ID" value={projectData.cloudCredentials.AZURE_SUBSCRIPTION_ID} onChange={handleChange} />
                  </label>
                  <label>
                    <strong>AZURE_TENANT_ID:</strong>
                    <input type="text" name="AZURE_TENANT_ID" value={projectData.cloudCredentials.AZURE_TENANT_ID} onChange={handleChange} />
                  </label>
                  <label>
                    <strong>AZURE_CLIENT_ID:</strong>
                    <input type="text" name="AZURE_CLIENT_ID" value={projectData.cloudCredentials.AZURE_CLIENT_ID} onChange={handleChange} />
                  </label>
                  <label>
                    <strong>AZURE_CLIENT_SECRET:</strong>
                    <input type="password" name="AZURE_CLIENT_SECRET" value={projectData.cloudCredentials.AZURE_CLIENT_SECRET} onChange={handleChange}/>
                  </label>
                  
                </div>
              )}
              {projectData.cloud_provider === 'GCP' && (
                <div>
                  <label>
                    <strong>GCP_PROJECT_ID:</strong>
                    <input type="text" name="GCP_PROJECT_ID" value={projectData.cloudCredentials.GCP_PROJECT_ID} onChange={handleChange} />
                  </label>
                  <label>
                    <strong>GCP_CLIENT_EMAIL:</strong>
                    <input type="text" name="GCP_CLIENT_EMAIL" value={projectData.cloudCredentials.GCP_CLIENT_EMAIL} onChange={handleChange} />
                  </label>
                  <label>
                    <strong>GCP_PRIVATE_KEY:</strong>
                    <input type="password" name="GCP_PRIVATE_KEY" value={projectData.cloudCredentials.GCP_PRIVATE_KEY} onChange={handleChange}/>
                  </label>
                </div>
              )}
            </Grid>
          </Grid>
          <ConfirmationSecretModal
            open={openDialog}
            onClose={handleCloseDialog}
            onConfirm={handleConfirmSecret}
            message={message}
            messageCompl={messageCompl}
          />
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px', width: isMobile ? '100%' : '94.3%', marginLeft: isMobile ? '0%' : '2.5%' }}>
            <button type="submit" style={{width: '140px', height: '40px'}}>Save Changes</button>
          </div>          
          </form>        
        </div>
      )}
    </div>      
  );
};

export default EditSecret;

       /* <img src={logoMap.computecomponent[projectData.compute_component]} alt="ComputeComponent Logo" style={{ width: '20px', height: '20px', marginRight: '10px' }} />
                  {toUpperCase(projectData.compute_component)}*/