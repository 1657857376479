import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {CssBaseline, useMediaQuery, useTheme } from '@mui/material';
import StepWizard from 'react-step-wizard';
import NavBar from './NavBar';
import ProjectList from './ProjectList';
import { encryptData, fetchPublicKey} from '../services/cryptoService'; 
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';

const StepWizardComponent = () => {
  const [setLoading] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [publicKey, setPublicKey] = useState(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [formData, setFormData] = useState({
    projectName: '',
    programmingLanguage: '',
    progLangVersion: '',
    deploymentTarget: {
      cloudProvider: '',
      computeComponent: '',
      computeCompDev: {
      },
      computeCompQa: {
      },
      computeCompPrd: {
      },
      credentialsAWS: {
        AWS_ACCESS_KEY_ID: '',
        AWS_SECRET_ACCESS_KEY: '',
        AWS_DEFAULT_REGION: ''
      },
      credentialsAzure: {
        AZURE_SUBSCRIPTION_ID: '',
        AZURE_TENANT_ID: '',
        AZURE_CLIENT_ID: '',
        AZURE_CLIENT_SECRET: ''
      },
      credentialsGCP: {
        GCP_PROJECT_ID: '',
        GCP_CLIENT_EMAIL: '',
        GCP_PRIVATE_KEY: ''
      }
    },
    repositoryCode: {
      repositoryProvider: '',
      repositoryUrl: '',
      branch: '',
      credentials: {
        username: '',
        password: ''
      }
    }
  });

  const handleUpdateFormData = (newData) => {
    setFormData(prevState => {
      // Crea un nuevo objeto para almacenar los nuevos datos
      const updatedData = { ...prevState };
  
      // Función recursiva para fusionar objetos profundamente
      const mergeDeep = (target, source) => {
        for (const key in source) {
          if (source[key] !== undefined && source[key] !== null) {
            if (typeof source[key] === 'object' && !Array.isArray(source[key])) {
              if (!target[key]) {
                target[key] = {};
              }
              mergeDeep(target[key], source[key]);
            } else {
              target[key] = source[key];
            }
          }
        }
      };
  
      mergeDeep(updatedData, newData);
      //alert(JSON.stringify(updatedData, null, 2));
      return updatedData;
    });
  };

  // Obtener la clave pública al montar el componente
  useEffect(() => {
    const loadPublicKey = async () => {
      try {
        const key = await fetchPublicKey();
        setPublicKey(key);
      } catch (error) {
        console.error('Error fetching public key:', error);
      }
    };
    loadPublicKey();
  }, []);

  const handleSubmit = async formData => {
    try {
      const token = localStorage.getItem('token');
      const DataTransfer = JSON.stringify(formData);
      //alert(JSON.stringify(DataTransfer, null, 2));
      const encryptedData = await encryptData(DataTransfer, publicKey);
      const payload = JSON.stringify(encryptedData); 
      
      const projectResponse = await fetch('https://doaas-backend-q52lot4npq-uc.a.run.app/api/projects', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: payload  // Convertir a JSON
      });
  
      if (!projectResponse.ok) {
        throw new Error(`HTTP error! status: ${projectResponse.status}`);
      }
      const result = await projectResponse.json();
        console.log('Project created successfully:', result);
      navigate('/homeNew');
    } catch (error) {
      console.error('There was a problem with the fetch operation: ' + error.message);
    }
  };

  const handleProjectSelect = (projectName) => {
    setLoading(true); // Set loading to true when a new project is selected
    localStorage.setItem('selectedProjectName', projectName);
    navigate('/homeNew'); // Navega al dashboard
    setDrawerOpen(false); // Cierra el drawer al seleccionar un proyecto
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <div style={{ display: 'flex', height: '100vh', flexDirection: 'column', overflow: isMobile ? '' : 'hidden'}}>
      <CssBaseline />
      <div style={{ display: 'flex', flex: 1, overflow: 'hidden', maxHeight: '70px'}}>
        <NavBar onSelectProject={handleProjectSelect} open={drawerOpen} handleDrawerToggle={handleDrawerToggle} />
      </div>
      <div style={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
        {!isMobile && (
          <ProjectList onSelectProject={handleProjectSelect} open={drawerOpen} onClose={handleDrawerToggle}/>
        )}
      </div>
      <div >
        <StepWizard>
          <Step1 formData={formData} updateFormData={handleUpdateFormData} />
          <Step2 formData={formData} updateFormData={handleUpdateFormData} />
          <Step3 formData={formData} updateFormData={handleUpdateFormData} />
          <Step4 formData={formData} updateFormData={handleUpdateFormData} handleSubmit={handleSubmit} />
        </StepWizard>
      </div>
    </div>
  );
};

export default StepWizardComponent;