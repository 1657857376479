import React, { useEffect, useState } from 'react';
import { Drawer, List, ListItem, ListItemText, Toolbar, Typography, useMediaQuery, useTheme, Box } from '@mui/material';
import '../styles/projectList.css'; 

const ProjectList = ( { onSelectProject, open, onClose } ) => {
  const [projects, setProjects] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchProjects = async () => {
      const token = localStorage.getItem('token'); // Asumiendo que tienes el token almacenado en localStorage

      try {
        const response = await fetch('https://doaas-backend-q52lot4npq-uc.a.run.app/api/projects/user-projects', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setProjects(data);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    fetchProjects();
  }, []);

  const ProjectListContent = (
    <Box className="project-list" sx={{ maxHeight: '200px', overflowY: 'auto', backgroundColor: isMobile ? '#fff' : '#8a8bbb' }}>
      <List>
        {projects.map((project, index) => (
          <ListItem button key={index} onClick={() => onSelectProject(project.project_name)}>
            <ListItemText primary={project.project_name} sx={{ fontSize: '0.875rem', color: isMobile ? 'black' : 'white' }} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  if (!isMobile) {
    return (
      <Drawer 
        variant= "temporary" //"permanent"
        anchor="left"
        open={open}
        onClose={onClose}
        className="drawer"
        PaperProps={{ id: "myprojList", className: "drawerPaper" }}
        sx={{
          '& .MuiDrawer-paper': { width: '300px', backgroundColor: '#8a8bbb' },
          zIndex: (theme) => theme.zIndex.appBar - 1 // Ensure the drawer is below the app bar
        }}
      >
        <Toolbar />
        <div style={{ padding: '5px', marginTop: '-60px', marginBottom: '10px'}}>
          <Typography color= 'white' variant="h6">Deployments List</Typography>
        </div>
        {ProjectListContent}
      </Drawer>
    );
  } else {
    return ProjectListContent;
  }
};

export default ProjectList;
/*<List background-color= 'white'>
          {projects.map((project, index) => (
            <ListItem button key={index} onClick={() => onSelectProject(project.project_name)}> 
              <ListItemText primary={project.project_name} sx={{ fontSize: '0.875rem', color: 'white' }}/>
            </ListItem>
          ))}
        </List>
        
        
        
        */