// Importación de Web Crypto API (ya disponible en navegadores modernos)
const crypto = window.crypto || window.msCrypto;

// Variable para almacenar la clave pública
let cachedPublicKey = null;
let isFetchingPublicKey = false;

// Función para obtener la clave pública desde el backend
export async function fetchPublicKey() {
  if (!cachedPublicKey && !isFetchingPublicKey) {
    isFetchingPublicKey = true; // Marcar como fetching en proceso
    try {
        const response = await fetch('https://doaas-backend-q52lot4npq-uc.a.run.app/api/crypto/public-key');
        const data = await response.json();
        //alert('Clave pública obtenida y almacenada:\n' + JSON.stringify(data.publicKey, null, 2)); 
        if (data && data.publicKey) {
            const base64PublicKey = data.publicKey
                .replace('-----BEGIN PUBLIC KEY-----', '')
                .replace('-----END PUBLIC KEY-----', '')
                .replace(/\n/g, '');  // Eliminar los saltos de línea
                //alert('base64PublicKey:\n' + JSON.stringify(base64PublicKey, null, 2)); 
            cachedPublicKey = base64PublicKey
            //alert('Clave pública obtenida y almacenada:\n' + JSON.stringify(cachedPublicKey, null, 2)); 
            //console.log('Clave pública obtenida y almacenada:', cachedPublicKey);
        } else {
            console.error('Error: Clave pública no recibida o es indefinida:', data);
        }
    } catch (error) {
        //alert('Error al obtener la clave pública:\n' + JSON.stringify(error, null, 2));
        console.error('Error al obtener la clave pública:', error);
    } finally {
        isFetchingPublicKey = false; // Finaliza el proceso de fetching
    }
  } else if (isFetchingPublicKey) {
    console.log('Esperando a que la clave pública sea obtenida...');
  }  else {
    //alert('Usando clave pública cacheada:\n' + JSON.stringify(cachedPublicKey, null, 2)); 
    console.log('Usando clave pública cacheada:', cachedPublicKey);
}
  return cachedPublicKey;
}

// Genera una clave AES de 256 bits
export async function generateAESKey() {
  const aesKey = await crypto.subtle.generateKey(
    {
      name: "AES-GCM",
      length: 256,
    },
    true,
    ["encrypt", "decrypt"]
  );

  return aesKey;
}

// Exporta la clave AES en formato crudo (ArrayBuffer)
export async function exportAESKey(aesKey) {
  return await crypto.subtle.exportKey("raw", aesKey);
}

// Encripta los datos usando la clave AES
export async function encryptWithAES(aesKey, plaintext) {
  const iv = crypto.getRandomValues(new Uint8Array(12)); // Inicialización vector (IV)
  const encodedText = new TextEncoder().encode(plaintext);

  const ciphertext = await crypto.subtle.encrypt(
    {
      name: "AES-GCM",
      iv: iv,
    },
    aesKey,
    encodedText
  );

  const tagLength = 16; // La longitud del tag de autenticación para AES-GCM es de 16 bytes
  const tag = ciphertext.slice(-tagLength); // Obtener el tag del final del ciphertext
  const encryptedData = ciphertext.slice(0, -tagLength); // Remover el tag del ciphertext
  
  return { iv, encryptedData, tag };
}

// Encripta la clave AES con RSA
export async function encryptAESKeyWithRSA(aesKeyRaw, publicKey) {
    //alert('encryptAESKeyWithRSA:\n' + JSON.stringify(publicKey, null, 2));
    // Convertir la clave pública de Base64 a ArrayBuffer
  const publicKeyArrayBuffer = base64ToArrayBuffer(publicKey);
  if (publicKeyArrayBuffer.byteLength === 0) {
    console.error('ArrayBuffer vacío. Posible problema en la conversión.');
    //alert('Error: ArrayBuffer vacío.');
  } else {
    //alert('Clave pública convertida a ArrayBuffer:\n' + JSON.stringify(publicKeyArrayBuffer));
    //alert('ArrayBuffer no vacío. Longitud: ' + publicKeyArrayBuffer.byteLength);
  }
  const rsaEncryptKey = await crypto.subtle.importKey(
    "spki",
    publicKeyArrayBuffer,
    {
      name: "RSA-OAEP",
      hash: "SHA-256",
    },
    false,
    ["encrypt"]
  );

  const encryptedKey = await crypto.subtle.encrypt(
    {
      name: "RSA-OAEP",
    },
    rsaEncryptKey,
    aesKeyRaw
  );

  return encryptedKey;
}

// Convierte un string en ArrayBuffer
export function stringToArrayBuffer(str) {
  const encoder = new TextEncoder();
  return encoder.encode(str);
}

// Convierte un ArrayBuffer en base64
export function arrayBufferToBase64(buffer) {
  const bytes = new Uint8Array(buffer);
  let binary = '';
  for (let i = 0; i < bytes.byteLength; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

// Convierte un base64 en ArrayBuffer
export function base64ToArrayBuffer(base64) {
    try {
      // Decodifica la cadena base64 en una cadena binaria
      const binaryString = window.atob(base64);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
  
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
  
      return bytes.buffer; // Devuelve el ArrayBuffer
    } catch (error) {
      console.error('Error converting base64 to ArrayBuffer:', error);
      throw error; // Relanzar el error para manejarlo donde se use
    }
  }

// Función principal para encriptar los datos y la clave
export async function encryptData(plaintext, publicKey) {
  // Generar la clave AES
  const aesKey = await generateAESKey();

  // Exportar la clave AES en formato crudo
  const aesKeyRaw = await exportAESKey(aesKey);

  // Encriptar los datos con AES
  const { iv, encryptedData, tag } = await encryptWithAES(aesKey, plaintext);

  // Encriptar la clave AES con la clave pública RSA
  const encryptedAESKey = await encryptAESKeyWithRSA(aesKeyRaw, publicKey);

  // Retornar la clave AES encriptada y los datos encriptados, todo en base64
  return {
    encryptedAESKey: arrayBufferToBase64(encryptedAESKey),
    iv: arrayBufferToBase64(iv),
    ciphertext: arrayBufferToBase64(encryptedData),
    tag: arrayBufferToBase64(tag), // Añadir el tag en la respuesta
  };
}

/*encryptedAESKey: arrayBufferToBase64(encryptedAESKey),
    iv: arrayBufferToBase64(iv),
    ciphertext: arrayBufferToBase64(ciphertext),
    
    encryptedAESKey: encryptedAESKey,
    iv: iv,
    ciphertext: ciphertext,*/