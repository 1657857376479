import React from 'react';
import { useFormik } from 'formik';
import { Select, MenuItem, ListItemText, ListItemIcon, FormControl } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import GHlogo from '../assets/github_logo.png';
import GLlogo from '../assets/gitlab_logo.png';
import BBlogo from '../assets/Bitbucket_logo.png';
import AzRlogo from '../assets/azure-repos-logo.png';
import '../styles/step1.css';

const optionsCodeR = [
    { value: 'github', label: 'GitHub', icon: GHlogo },
    { value: 'gitlab', label: 'GitLab', icon: GLlogo },
    { value: 'bitbucket', label: 'Bitbucket', icon: BBlogo },
    { value: 'azure', label: 'Azure Repository', icon: AzRlogo },
    // Agrega más opciones aquí
];

const CustomOption = ({ data, ...props }) => (
    <MenuItem {...props} style={{ display: 'flex', alignItems: 'center' }}>
      {data.icon && (
        <ListItemIcon>
          <img src={data.icon} alt={data.label} style={{ width: '30px', height: '30px', marginRight: '10px' }} />
        </ListItemIcon>
      )}
      <ListItemText>{data.label}</ListItemText>
    </MenuItem>
);
  
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 224, // Ajusta esta altura según tus necesidades (4.5 * 48px item height)
      width: 150,
    },
  },
};

const Step2 = ({ formData, updateFormData, nextStep, previousStep }) => {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: formData,
    enableReinitialize: true,
    onSubmit: (values) => {
      updateFormData(values);
      nextStep();
    },
  });

  const handlePrevious = () => {
    updateFormData(formik.values);
    previousStep();
  };

  const getRepoUrlExample = (repositoryProvider) => {
    switch (repositoryProvider) {
      case 'github':
        return 'https://github.com/username/repo.git';
      case 'gitlab':
        return 'https://gitlab.com/username/repo.git';
      case 'bitbucket':
        return 'https://bitbucket.org/username/repo.git';
      case 'azure':
        return 'https://dev.azure.com/username/repo.git';
      default:
        return '';
    }
  };

  return (
    <div className="main-container-step1">
      <form className="NewDep-form-container-step1" onSubmit={formik.handleSubmit}>
        <IconButton 
        onClick={() => navigate('/homeNew')} 
        style={{
          position: 'absolute',
          top: '80px',
          right: '20px',
          backgroundColor: '#f0f0f0', // Color tenue
          width: '30px', // Ancho y alto igual para hacerlo redondo
          height: '30px',
          padding: '5px', // Ajuste del padding
          borderRadius: '50%', // Asegura que sea redondo
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center',
          zIndex: 1000 // Asegura que esté encima de otros elementos
          }}
        >
          <CloseIcon style={{ fontSize: '16px', color: '#888' }} /> {/* Tamaño pequeño y color tenue */}
        </IconButton>
        <h2>New Deployment</h2>
        <h4>All fields are required.</h4>
        <label>
          Code Repository Provider:
        </label>
        <FormControl fullWidth>
          <Select
            labelId="crepository-select-label"
            id="crepository-select"
            name="repositoryCode.repositoryProvider"
            value={formik.values.repositoryCode.repositoryProvider}
            onChange={formik.handleChange}
            required
            sx={{ backgroundColor: 'white', width: '100%', height: '40px'}}
            MenuProps={MenuProps}
            renderValue={(selected) => {
              const selectedOption = optionsCodeR.find((option) => option.value === selected);
              return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {selectedOption && selectedOption.icon && selectedOption.value !== '' && (
                    <img src={selectedOption.icon} alt={selectedOption.label} style={{ width: '30px', height: '30px', marginRight: '10px' }} />
                  )}
                  {selectedOption ? selectedOption.label : '--Select Repository Code Provider--'}
                </div>
              );
            }}
          >
            {optionsCodeR.map((option) => (
              <CustomOption key={option.value} data={option} value={option.value} />
            ))}
          </Select>
        </FormControl>
        {formik.values.repositoryCode.repositoryProvider !== '' && (
          <>
            <label>
              URL:
              <input
                type="text"
                name="repositoryCode.repositoryUrl"
                onChange={formik.handleChange}
                required
                value={formik.values.repositoryCode.repositoryUrl}
                placeholder={getRepoUrlExample(formik.values.repositoryCode.repositoryProvider)}
              />
            </label>
            <label>
              Username:
              <input
                type="text"
                name="repositoryCode.credentials.username"
                onChange={formik.handleChange}
                required
                value={formik.values.repositoryCode.credentials.username}
              />
            </label>
            <label>
              PAT (Personal Access Token):
              <input
                type="password"
                name="repositoryCode.credentials.password"
                onChange={formik.handleChange}
                required
                value={formik.values.repositoryCode.credentials.password}
              />
            </label>
          </>
        )}
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '60px' }}>
          <div>
            <button type="button" onClick={handlePrevious}>Previous</button>
          </div>
          <div>
            <button type="submit">Next</button>
          </div>
        </div>
      </form>
    </div>
  );
};
export default Step2;