import React, { useState, useEffect, useCallback } from 'react';
import GaugeChart from 'react-gauge-chart';
//import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Button, Box, Select, MenuItem, Checkbox, Input, Tooltip } from '@mui/material';
import { collection, onSnapshot, where, query } from 'firebase/firestore';
import { db } from './firebaseConfig';
import '../styles/tooltips.css';

const EnvironmentGauge = ({ environment, projectName }) => {
  //const [deploymentDate, setDeploymentDate] = useState(null);
  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState('');
  const [deploymentId, setDeploymentId] = useState(null);  // Guardar el ID del despliegue registrado en el historial
  const [finishType, setFinishType] = useState(null); 
  const [gaugeProgress, setGaugeProgress] = useState(0);
  const [targetProgress, setTargetProgress] = useState(0);
  const [percentage, setPercentage] = useState(0);  // Para mostrar el porcentaje recibido
  const [lastExecutionDate, setLastExecutionDate] = useState('Never');  // Estado para la última ejecución
  const [customTag, setCustomTag] = useState(''); // Estado para el tag personalizado
  const [isCustomTagEnabled, setIsCustomTagEnabled] = useState(false); // Estado para habilitar/deshabilitar el campo de texto de tag personalizado

  const handleCustomTagChange = (event) => {
    setCustomTag(event.target.value);
  };

  const handleCustomTagToggle = (event) => {
    setIsCustomTagEnabled(event.target.checked);
    if (!event.target.checked) {
      setCustomTag('');
    }
  };

  const fetchTags = useCallback(async () => {
    if (!projectName) {
        console.error('Project name is undefined');
        return;
    }
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`https://doaas-backend-q52lot4npq-uc.a.run.app/api/projects/tags?projectName=${projectName}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setTags(data);
    } catch (error) {
      console.error('Error fetching tags:', error);
    }
  }, [projectName]);

  useEffect(() => {
    if (environment === 'Production') {
      fetchTags();
    }
  }, [environment, fetchTags]);

  // Obtener la última ejecución desde la base de datos antes de iniciar el despliegue
  const fetchLastExecution = async () => {
    const token = localStorage.getItem('token');
    
    try {
      const response = await fetch(`https://doaas-backend-q52lot4npq-uc.a.run.app/api/deployments/last-execution?projectName=${projectName}&environment=${environment}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Error fetching last execution.');
      }

      const result = await response.json();

      if (result && result.date_inicio) {
        setLastExecutionDate(new Date(result.date_inicio).toLocaleString());
      } else {
        setLastExecutionDate('Never');  // Si no hay ejecución previa
      }
    } catch (error) {
      console.error('Error fetching last execution:', error);
      setLastExecutionDate('Never');
    }
  };

  // Llamar al endpoint para obtener la última ejecución cuando se monta el componente
  useEffect(() => {
    fetchLastExecution();
  }, [projectName, environment]);

  // Resetea los estados al iniciar una nueva ejecución
  const resetGauge = () => {
    setGaugeProgress(0);
    setTargetProgress(0);
    setPercentage(0);
    //setFinishType('');
  };

  // Escuchar Firebase para obtener el progreso del despliegue basado en deploymentId, projectName y environment
  useEffect(() => {
    if (!deploymentId || !projectName || !environment) return; // Asegúrate de tener los valores necesarios

    const logsRef = collection(db, 'logs');

    // Realizar consultas a Firebase filtrando por deploymentId, projectName y environment
    const q = query(
      logsRef,
      where('project_name', '==', projectName),
      where('environment', '==', environment),
      where('deploymentId', '==', deploymentId)
    );

    // Escuchar los documentos cuyo ID contiene `deploymentId`, `projectName` y `environment`
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((docSnapshot) => {
        const docId = docSnapshot.id;
        const docData = docSnapshot.data();
        const receivedPercentage = parseInt(docData.porcentaje, 10);  // Obtener el porcentaje desde el campo 'porcentaje'
        const receivedType = docData.type; 
        //console.log("docId:",docId);
        // Verificar si el nombre del documento contiene el deploymentId, projectName y environment
        if (docId.includes(deploymentId) && docId.includes(projectName) && docId.includes(environment) && receivedPercentage > percentage) {
          //const parts = docId.split('-');
          //const receivedPercentage = parseInt(parts[3], 10);  // El porcentaje es la cuarta parte del ID
          // Actualiza el porcentaje que se muestra
          setPercentage(receivedPercentage);  
          // Actualiza el progreso objetivo del tacómetro
          setTargetProgress(receivedPercentage);
          // Si se alcanza el 100%, detener el listener de Firebase
          if (receivedPercentage === 100) {
            setGaugeProgress(100);
            setPercentage(100);
            setFinishType(receivedType)
            console.log("Se logró el 100%:");
            unsubscribe();
          }
        }
      });
    });
    // Cleanup: Desuscribirnos del listener cuando el componente se desmonte
    return () => unsubscribe();
  }, [deploymentId, projectName, environment, percentage]);

  // Animar el tacómetro suavemente hacia el progreso objetivo
  useEffect(() => {
    if (gaugeProgress < targetProgress) {
      const timer = setTimeout(() => {
        setGaugeProgress(gaugeProgress + 10);
      }, 50); // Ajustar la velocidad de la animación
      //console.log("GaugeProgress:",gaugeProgress);
      return () => clearTimeout(timer);
    }
  }, [gaugeProgress, targetProgress]);

  const adjustedProgress =  gaugeProgress;

  // Llamar al endpoint /start para iniciar el registro del despliegue
  const startDeploymentHistory = async () => {
    const token = localStorage.getItem('token');

    try {
      const response = await fetch('https://doaas-backend-q52lot4npq-uc.a.run.app/api/deployments/start', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          projectName: projectName,
          environment: environment
        })
      });

      if (!response.ok) {
        throw new Error(`Failed to start deployment history. Status: ${response.status}`);
      }

      const result = await response.json();
      console.log('Deployment history started:', result);

      setDeploymentId(result.id);
      setLastExecutionDate(new Date().toLocaleString());
      return result.id;
    } catch (error) {
      console.error('Error starting deployment history:', error.message);
      return null;
    }
  };

  const handleDeploy = async () => {
    resetGauge(); // Resetear al iniciar nuevo despliegue
    const token = localStorage.getItem('token');
    //const userId = localStorage.getItem('userId');
    let branch = '';
    let tag = '';

    switch (environment) {
      case 'Develop':
        branch = 'develop';
        tag = 'latest';
        break;
      case 'Quality':
        branch = 'master';
        tag = isCustomTagEnabled ? customTag : 'latest';
        break;
      case 'Production':
        branch = '';
        tag = selectedTag;
        break;
      default:
        break;
    }

    // Llamar a /start antes de realizar el despliegue
    const deploymentHistoryId = await startDeploymentHistory();
    if (!deploymentHistoryId) {
      alert('Failed to start deployment history. Deployment aborted.');
      return;
    }

    const bodyData = JSON.stringify({ projectName, environment, branch, deploymentHistoryId, tag })
    //alert(JSON.stringify(bodyData, null, 2));
    try { 
      const response = await fetch('https://doaas-backend-q52lot4npq-uc.a.run.app/api/deploy', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: bodyData
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      /*const result = await response.json();
      console.log('Deployment successful:', result);*/
      //setDeploymentDate(new Date());
    } catch (error) {
      console.error('Error during deployment:', error.message);
    }
  };

  const handleCancel = () => {
    // Lógica para cancelar el despliegue
  };

  const handleTagChange = (event) => {
    setSelectedTag(event.target.value);
  };

  const gaugeLabel = adjustedProgress === 100 ? 'Deployed' : adjustedProgress === 0 ? 'Undeploy' : adjustedProgress > 0 && adjustedProgress < 100  ? 'Deploying' : 'Undeploy';

  return (
    <div style={{ textAlign: 'center', margin: '20px' }}>
      <h3>{environment}</h3>
      <GaugeChart 
        id={`gauge-chart-${environment}`}
        nrOfLevels={100}
        percent={Math.min(adjustedProgress / 100, 1)}  // Limitar el valor máximo a 1 {progress / 100}
        textColor="#6a0dad"
        arcPadding={0.02}
        colors={['#6a0dad', '#00FF00']}  // Colores dinámicos
        needleColor="#6a0dad"
        needleBaseColor="#6a0dad"
        hideText
      />
      <div style={{ textAlign: 'center', fontSize: '14px', marginTop: '10px' }}>
        {gaugeLabel}
         {adjustedProgress === 100 && <div>{finishType}</div>}
        {/*Mostrar el porcentaje recibido debajo del tacómetro */}
        {adjustedProgress <= 100 && <div>{`Executed: ${percentage}%`}</div>}
        {/* Mostrar la última ejecución  */}
        <div>Last Execution: {lastExecutionDate}</div>
      </div>
      
      <Box mt={2} display="flex" justifyContent="center" alignItems="center">
        {environment === 'Quality' && (
          <>
            <Tooltip title="Enable to set a custom tag for this deployment" arrow>
              <Checkbox
                checked={isCustomTagEnabled}
                onChange={handleCustomTagToggle}
                color="primary"
              />
            </Tooltip>
            {isCustomTagEnabled && (
              <Input
                value={customTag}
                onChange={handleCustomTagChange}
                placeholder="New tag"
                style={{ width: '105px',
                maxHeight: '36px',
                marginRight: '10px',
                fontSize: '16px',
                padding: '8px', // Para un diseño simple sin borde visible
                
                borderRadius: '4px'}}
                disableUnderline
              />
            )}
          </>
        )}
        {environment === 'Production' && (
          <Select
            value={selectedTag}
            onChange={handleTagChange}
            displayEmpty
            style={{ marginRight: '10px', padding: '10px', fontSize: '16px', width: '105px', maxHeight: '35px' }}
          >
            <MenuItem value="" disabled>Tag</MenuItem>
            {tags.map(tag => (
              <MenuItem key={tag} value={tag}>{tag}</MenuItem>
            ))}
          </Select>
        )}
        <Button variant="contained" color="primary" onClick={handleDeploy} style={{ marginRight: '10px', padding: '10px', fontSize: '16px', width: '100px', maxHeight: '35px' }}>
          Deploy
        </Button>
      </Box>
    </div>
  );
};

export default EnvironmentGauge;

/*        <Button disabled variant="contained" color="secondary" onClick={handleCancel} style={{ marginRight: '10px', padding: '10px', fontSize: '16px', width: '100px', maxHeight: '35px' }}>
          Cancelar
        </Button>

<div style={{ marginTop: '20px' }}>
        {steps.map((step, index) => (
          <div
            key={index}
            data-tooltip-id={`tooltip-${index}`}
            data-tooltip-content={step}
            style={{
              width: '20%',
              height: '10px',
              backgroundColor: index < filledArcs ? '#00FF00' : '#d3d3d3',
              display: 'inline-block',
              cursor: 'pointer',
              marginTop: '5px',
            }}
          >
            <ReactTooltip id={`tooltip-${index}`} />
          </div>
        ))}
      </div>*/