import React, { useState, useEffect }  from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/LogoOctopusV.jpg';
import '../styles/LoginForm.css';
import ConfirmationLoginModal from './MessageModal';
import { encryptData, fetchPublicKey} from '../services/cryptoService';

function LoginForm() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [publicKey, setPublicKey] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState('');
  const [messageCompl, setMessageCompl] = useState('');

  // Obtener la clave pública al montar el componente
  useEffect(() => {
    const loadPublicKey = async () => {
      try {
        const key = await fetchPublicKey();
        setPublicKey(key);
      } catch (error) {
        console.error('Error fetching public key:', error);
      }
    };
    loadPublicKey();
  }, []);

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setMessage(null);
  };

  const handleConfirmLogin = async () => {
      try {
        handleCloseDialog();
      } catch (error) {
        console.error('Error Login:', error);
      }
  };

  const handleLogin = async (event) => {
    event.preventDefault();

    if (!publicKey) {
      console.error("La clave pública no está disponible aún.");
      setMessage("Error:");
      setMessageCompl("Clave pública no cargada.");
      setOpenDialog(true);
      return;
    }
    
    try {
      // Encriptar el username y el password antes de enviarlos
      const encryptedUsername = await encryptData(username, publicKey);
      const encryptedPassword = await encryptData(password, publicKey);
      //const response = await axios.post('http://localhost:3000/api/auth/login', { 
      const response = await axios.post('https://doaas-backend-q52lot4npq-uc.a.run.app/api/auth/login', {
        username: encryptedUsername,
        password: encryptedPassword
      });
      if (response.status === 200) {
        console.log('Logged in successfully:', response.data);
        localStorage.setItem('token', response.data.token);
        // Solicitar la clave pública después del login exitoso
        await fetchPublicKey();
        navigate('/homeNew');
      } else {
        console.log('No data received in the response');
      }
    } catch (error) {
      console.error('Error during login:', error);
      if (error.response && error.response.data) {
        // Specific actions based on the error response
        console.error('Error details:', error.response.data);
        setMessage("Error logging in:");
        setMessageCompl("Incorrect (Username/Password).");
        setOpenDialog(true);
        //alert(error.response.data.message || 'Error logging in');
      } else {
        // Handle general errors
        console.error('Error with no server response:', error.message);
        setMessage("Error with no server response:");
        setMessageCompl(error.message);
        setOpenDialog(true);
        //alert(error.message);
      }      
    }
  };

  // Cambiar el fondo cuando el componente se monta
  useEffect(() => {
    document.body.style.backgroundColor = '#ffffff'; // #332c3c'#f8f8f8' Establece tu color de fondo deseado aquí

    // Reestablecer al desmontar el componente
    return () => {
      document.body.style.backgroundColor = ''; // Vuelve al fondo original o a un color predeterminado
    };
  }, []);
  
  // Verificar si los campos están completos para habilitar/deshabilitar el botón de Login
  const isLoginDisabled = !username || !password;

  return (
    <div className="login-container">
      <div className="background-image">
        <span className="custom-text1"></span>
      </div>
      <div className="login-form">
        <center>
          <img src={logo} alt="Logo" className="logo" />
        </center>
        <h1 className="custom-h1">Welcome,</h1>
        <h3 className="custom-h3">Sign in</h3>
        <form onSubmit={handleLogin} className="login-form-container">
          <label>
            Username:
            <input 
              type="text" 
              value={username} 
              onChange={(e) => setUsername(e.target.value)} 
              placeholder="username or e-mail"
              autoComplete="current-username"
              required
            />
          </label>
          <label>
            Password:
            <input 
              type="password" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              placeholder="password" 
              autoComplete="current-password"
              required
            />
          </label>
          <div className="button-group">
            <button type="submit" disabled={isLoginDisabled}>Login</button>
            <button 
              type="button"  
              onClick={() => {setUsername(''); setPassword('');}} 
            >
              Cancel
            </button> 
          </div>
          <ConfirmationLoginModal
            open={openDialog}
            onClose={handleCloseDialog}
            onConfirm={handleConfirmLogin}
            message={message}
            messageCompl={messageCompl}
          />
        </form>
        <div className="extra-links" style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '20px' }}>
          <div style={{ flex: 1 }}>
            <center>
              <p>
                <a className="custom-text1" href="#register" onClick={
                    (e) => {
                      e.preventDefault();
                      navigate('/forgot-password');
                    }
                }>Forgot Password?</a>
              </p>
            </center>
          </div>
          <div style={{ flex: 1 }}>
            <center>
              <p>
                <span className="custom-text1">New Octopus Enginner? </span><a className="custom-text1" href="#register" onClick={
                    (e) => {
                      e.preventDefault();
                      navigate('/register');
                    }
                  }>Create an account.</a>
              </p>
            </center>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginForm;