import React from 'react';

const SonarMetrics = ({ projectName }) => {
  const baseUrl = `https://sonarcloud.io/api/project_badges/measure?project=joshlopez07_${projectName}&metric=`;

  // Mapeo de métricas a etiquetas descriptivas y enlaces
  const metrics = [
    { label: "Bugs", metric: "bugs" },
    { label: "Code Smells", metric: "code_smells" },
    { label: "Coverage", metric: "coverage" },
    { label: "Duplicated Lines (%)", metric: "duplicated_lines_density" },
    { label: "Lines of Code", metric: "ncloc" },
    { label: "Reliability Rating", metric: "reliability_rating" },
    { label: "Security Rating", metric: "security_rating" },
    { label: "Technical Debt", metric: "sqale_index" },
    { label: "Maintainability Rating", metric: "sqale_rating" },
    { label: "Vulnerabilities", metric: "vulnerabilities" },
    //{ label: "Quality Gate Status", metric: "alert_status" },
  ];

  return (
    <div style={{ marginTop: '20px', textAlign: 'center' }}>
      <div style={{ 
            display: 'flex', 
            flexWrap: 'wrap', 
            justifyContent: 'center',  
          }}>
        {metrics.map(({ label, metric }) => (
          <div 
            key={metric}
            style={{
                flex: '1 1 150px',  // Flexibilidad para ajustarse a dispositivos
                maxWidth: '150px',   // Ancho máximo para dispositivos grandes
                minWidth: '120px',   // Ancho mínimo en dispositivos pequeños
                textAlign: 'center',
                padding: '8px',
                boxSizing: 'border-box',
            }}>
            <a 
              href={`https://sonarcloud.io/summary/new_code?id=joshlopez07_${projectName}`} 
              target="_blank" 
              rel="noopener noreferrer">
              <img 
                src={`${baseUrl}${metric}`} 
                alt={`${label} badge`} 
                style={{ width: '100%', height: '30px', maxWidth: '150px' }} 
              />
            </a>
          </div>
        ))}
      </div>
      <div style={{
        textAlign: 'right',
        marginTop: '20px',
      }}>
        <a
          href={`https://sonarcloud.io/summary/new_code?id=joshlopez07_${projectName}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://sonarcloud.io/images/project_badges/sonarcloud-orange.svg"
            alt="SonarCloud"
            style={{ width: '95px' }}  // Tamaño ajustable del logo
          />
        </a>
      </div>
    </div>
  );
};

export default SonarMetrics;

//      <h2>Code Review Metrics</h2>