import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { CssBaseline, Typography, Grid, useMediaQuery, useTheme } from '@mui/material';
import NavBar from './NavBar';
import '../styles/EditProject.css';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import GOlogo from '../assets/Go-Logo_Blue.png';
import Javalogo from '../assets/java-logo.png';
import JavaScriptlogo from '../assets/JavaScript-logo.png';
import Pythonlogo from '../assets/Python.png';
import Csharplogo from '../assets/Csharp_Logo.png';
import Cpplogo from '../assets/cpp.png'
import Phplogo from '../assets/php.png';
import typescriptlogo from '../assets/typescript_logo.png';
import Nodelogo from '../assets/node-js.png';
import Rubylogo from '../assets/ruby-logo.png';
import Swiftlogo from '../assets/swift_logo.png';
import Kotlinlogo from '../assets/Kotlin_Icon.png';

import AWSlogo from '../assets/aws_logo.png';
import AZlogo from '../assets/azure-logo.png';
import GCPlogo from '../assets/gcp_logo.png';

import GHlogo from '../assets/github_logo.png';
import GLlogo from '../assets/gitlab_logo.png';
import BBlogo from '../assets/Bitbucket_logo.png';
import AzRlogo from '../assets/azure-repos-logo.png';

import AWSEC2logo from '../assets/aws_ec2_logo.png';
import AWSLambdalogo from '../assets/aws_lambda_logo.png';
import AWSEKSlogo from '../assets/aws_eks.png';
import AWSFarlogo from '../assets/aws_fargate.png';
import AWSEBTlogo from '../assets/aws_ebt_logo.png';

import AzAKSlogo from '../assets/az_aks_logo.png';
import AzApSrlogo from '../assets/az_appServ_logo.png';
import AzContlogo from '../assets/az_container_logo.png';
import AzFunclogo from '../assets/az_funct_logo.png';
import AzVMslogo from '../assets/az_vms_logo.png';

import GCPAppEnglogo from '../assets/GCP_AppEngine.png';
import GCPCFunctlogo from '../assets/GCP_CloudFunctions.png';
import GCPCRun from '../assets/GCP_CloudRun.png';
import GCPCompEng from '../assets/GCP_Compute_Engine.png';
import GCPGKE from '../assets/GCP_GKE.png';

const logoMap = {
    programmingLanguage: {
      javascript: JavaScriptlogo,
      python: Pythonlogo,
      java: Javalogo,
      golang: GOlogo,
      nodejs: Nodelogo,
      csharp: Csharplogo,
      cpp: Cpplogo,
      php: Phplogo,
      typescript: typescriptlogo,
      ruby: Rubylogo,
      swift: Swiftlogo,
      kotlin: Kotlinlogo,
    },
    cloudProvider: {
      AWS: AWSlogo,
      AZURE: AZlogo,
      GCP: GCPlogo,
    },
    computecomponent: {
      ec2: AWSEC2logo,
      lambda: AWSLambdalogo,
      eks: AWSEKSlogo,
      fargate: AWSFarlogo,
      elasticBeanstalk: AWSEBTlogo,
      vm: AzVMslogo,
      functions: AzFunclogo,
      aks: AzAKSlogo,
      containerInstances: AzContlogo,
      appService: AzApSrlogo,
      computeEngine: GCPCompEng,
      cloudFunctions: GCPCFunctlogo,
      gke: GCPGKE,
      cloudRun: GCPCRun,
      appEngine: GCPAppEnglogo,
    },
    repositoryProvider: {
      github: GHlogo,
      gitlab: GLlogo,
      bitbucket: BBlogo,
      azure: AzRlogo,
    },
  };

const capitalizeWords = (str) => {
  return str
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

const toUpperCase = (str) => {
    if (typeof str !== 'string') {
      throw new TypeError('El argumento debe ser una cadena de texto.');
    }
    return str.toUpperCase();
};

const EditProject = () => {
  const [projectData, setProjectData] = useState({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  //const location = useLocation(); useLocation, 

  const getProjectDetails = async (projectName) => {
    const token = localStorage.getItem('token');
    //alert(JSON.stringify(projectName, null, 2));
    try {
      const response = await fetch(`https://doaas-backend-q52lot4npq-uc.a.run.app/api/projects/details?name=${projectName}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const projectDetails = await response.json();
      //alert(JSON.stringify(projectDetails, null, 2));
      setProjectData(projectDetails);
      setLoading(false); // Set loading to false once the data is fetched
    } catch (error) {
      console.error('Error fetching project details:', error);
      setLoading(false); // Set loading to false in case of error
    }
  };

  const updateProject = async (projectName, projectData, type) => {
    const token = localStorage.getItem('token');

    const bodyData = JSON.stringify({
        computeComponent: projectData.compute_component,
        computeCompDevelopment: {
          componentName: projectData.computeCompDev.componentName,
          componentImagId: projectData.computeCompDev.componentImagId
        },
        computeCompQa: {
          componentName: projectData.computeCompQa.componentName,
          componentImagId: projectData.computeCompQa.componentImagId
        },
        computeCompProduction: {
          componentName: projectData.computeCompPrd.componentName,
          componentImagId: projectData.computeCompPrd.componentImagId
        }
    })

    const response = await fetch(`https://doaas-backend-q52lot4npq-uc.a.run.app/api/projects?name=${encodeURIComponent(projectName)}&type=${type}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: bodyData
    });
  
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  
    return response.json();
  };

  useEffect(() => {
    const selectedProjectName = localStorage.getItem('selectedProjectName');
    const fetchProjectDetails = async () => {
      getProjectDetails(selectedProjectName);
    };
    fetchProjectDetails();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProjectData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateProject(projectData.project_name, projectData, 'components');
      alert('Secrets updated successfully');
      navigate('/homeNew');
    } catch (error) {
      console.error('Error updating project:', error);
    }
  };

  return (
    <div style={{ display: 'flex', height: '100vh', flexDirection: 'column' }}>
      <CssBaseline />
      <div style={{ display: 'flex', flex: 1, overflow: 'hidden', maxHeight: '70px' }}>
        <NavBar />
      </div>
      {loading ? (
        <div style={{ textAlign: 'center' }}>
        <Typography variant="body1">Loading...</Typography>
        </div>
      ) : (
        <div className="main-container-editP">
          <form className="NewDep-form-container-editP" onSubmit={handleSubmit}>
            <IconButton 
              onClick={() => navigate('/homeNew')} 
              style={{
                position: 'absolute',
                top: '80px',
                right: '20px',
                backgroundColor: '#f0f0f0', // Color tenue
                width: '30px', // Ancho y alto igual para hacerlo redondo
                height: '30px',
                padding: '5px', // Ajuste del padding
                borderRadius: '50%', // Asegura que sea redondo
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                zIndex: 1000 // Asegura que esté encima de otros elementos
              }}
            >
              <CloseIcon style={{ fontSize: '16px', color: '#888' }} /> {/* Tamaño pequeño y color tenue */}
            </IconButton>
            <h1>Edit Variables</h1>
            <label disabled style={{ textAlign: 'center' }}>
                <strong>Deployment Name:</strong> {projectData.project_name}
            </label>
            <label disabled style={{ textAlign: 'center' }}>
                <strong>Programming Language:</strong> &nbsp;
                <img src={logoMap.programmingLanguage[projectData.programming_language]} alt="Language Logo" style={{ width: '20px', height: '20px', marginRight: '10px' }} />
                {capitalizeWords(projectData.programming_language)}
            </label>
            <label style={{ textAlign: 'center' }}>
              <strong>Provider:</strong> &nbsp;
              <img src={logoMap.cloudProvider[projectData.cloud_provider]} alt="Cloud Logo" style={{ width: '20px', height: '20px', marginRight: '10px' }} />
              {projectData.cloud_provider}
            </label>
            <label style={{ textAlign: 'center' }}>
              <strong>Compute Service:</strong> &nbsp;
              <img src={logoMap.computecomponent[projectData.compute_component]} alt="ComputeComponent Logo" style={{ width: '20px', height: '20px', marginRight: '10px' }} />
              {toUpperCase(projectData.compute_component)}
            </label>
            {projectData.cloud_provider === 'AWS' && projectData.compute_component === 'ec2' && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <h4>Dev Env.</h4>
                  <label>
                    Component Name:
                    </label>
                  <label>
                    Imagen ID:
                  </label>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <h4>QA Env.</h4>
                  <label>
                    Component Name:
                    </label>
                  <label>
                    Imagen ID:
                  </label>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <h4>Prod Env.</h4>
                  <label>
                    Component Name:
                     </label>
                  <label>
                    Imagen ID:
                  </label>
                </Grid>
              </Grid>
            )}
            {projectData.cloud_provider === 'AWS' && projectData.compute_component === 'lambda' && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <h4>Dev Env.</h4>
                  <label>
                    Component Name:
                    </label>
                  <label>
                    Imagen ID:
                  </label>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <h4>QA Env.</h4>
                  <label>
                    Component Name:
                    </label>
                  <label>
                    Imagen ID:
                  </label>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <h4>Prod Env.</h4>
                  <label>
                    Component Name:
                     </label>
                  <label>
                    Imagen ID:
                  </label>
                </Grid>
              </Grid>
            )}
            {projectData.cloud_provider === 'AWS' && projectData.compute_component === 'eks' && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <h4>Dev Env.</h4>
                  <label>
                    Component Name:
                    </label>
                  <label>
                    Imagen ID:
                  </label>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <h4>QA Env.</h4>
                  <label>
                    Component Name:
                    </label>
                  <label>
                    Imagen ID:
                  </label>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <h4>Prod Env.</h4>
                  <label>
                    Component Name:
                     </label>
                  <label>
                    Imagen ID:
                  </label>
                </Grid>
              </Grid>
            )}
            {projectData.cloud_provider === 'AZURE' && projectData.compute_component === 'aks' && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <h4>Dev Env.</h4>
                  <label>
                    Component Name:
                    </label>
                  <label>
                    Imagen ID:
                  </label>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <h4>QA Env.</h4>
                  <label>
                    Component Name:
                    </label>
                  <label>
                    Imagen ID:
                  </label>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <h4>Prod Env.</h4>
                  <label>
                    Component Name:
                     </label>
                  <label>
                    Imagen ID:
                  </label>
                </Grid>
              </Grid>
            )}
            {projectData.cloud_provider === 'AZURE' && projectData.compute_component === 'vm' && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <h4>Dev Env.</h4>
                  <label>
                    Component Name:
                    </label>
                  <label>
                    Imagen ID:
                  </label>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <h4>QA Env.</h4>
                  <label>
                    Component Name:
                    </label>
                  <label>
                    Imagen ID:
                  </label>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <h4>Prod Env.</h4>
                  <label>
                    Component Name:
                     </label>
                  <label>
                    Imagen ID:
                  </label>
                </Grid>
              </Grid>
            )}
            {projectData.cloud_provider === 'AZURE' && projectData.compute_component === 'appService' && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <h4>Dev Env.</h4>
                  <label>
                    Component Name:
                    </label>
                  <label>
                    Imagen ID:
                  </label>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <h4>QA Env.</h4>
                  <label>
                    Component Name:
                    </label>
                  <label>
                    Imagen ID:
                  </label>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <h4>Prod Env.</h4>
                  <label>
                    Component Name:
                     </label>
                  <label>
                    Imagen ID:
                  </label>
                </Grid>
              </Grid>
            )}
            {projectData.cloud_provider === 'GCP' && projectData.compute_component === 'gke' && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <h4>Dev Env.</h4>
                  <label>
                    Component Name:
                    </label>
                  <label>
                    Imagen ID:
                  </label>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <h4>QA Env.</h4>
                  <label>
                    Component Name:
                    </label>
                  <label>
                    Imagen ID:
                  </label>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <h4>Prod Env.</h4>
                  <label>
                    Component Name:
                     </label>
                  <label>
                    Imagen ID:
                  </label>
                </Grid>
              </Grid>
            )}
            {projectData.cloud_provider === 'GCP' && projectData.compute_component === 'appEngine' && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <h4>Dev Env.</h4>
                  <label>
                    Component Name:
                    </label>
                  <label>
                    Imagen ID:
                  </label>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <h4>QA Env.</h4>
                  <label>
                    Component Name:
                    </label>
                  <label>
                    Imagen ID:
                  </label>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <h4>Prod Env.</h4>
                  <label>
                    Component Name:
                     </label>
                  <label>
                    Imagen ID:
                  </label>
                </Grid>
              </Grid>
            )}
            {projectData.cloud_provider === 'GCP' && projectData.compute_component === 'computeEngine' && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <h4>Dev Env.</h4>
                  <label>
                    Component Name:
                    </label>
                  <label>
                    Imagen ID:
                  </label>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <h4>QA Env.</h4>
                  <label>
                    Component Name:
                    </label>
                  <label>
                    Imagen ID:
                  </label>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <h4>Prod Env.</h4>
                  <label>
                    Component Name:
                     </label>
                  <label>
                    Imagen ID:
                  </label>
                </Grid>
              </Grid>
            )}
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px', width: isMobile ? '100%' : '94.3%', marginLeft: isMobile ? '0%' : '2.5%' }}>
              <button type="submit" style={{width: '140px', height: '40px'}}>Save Changes</button>
            </div>          
          </form>        
        </div>
      )}
    </div>      
  );
};

export default EditProject;

       /* <label>
          Repository Username:
          <input type="text" name="repositoryUsername" value={projectData.repositoryUsername} onChange={handleChange} />
        </label>
        <label>
          Repository Password:
          <input type="password" name="repositoryPassword" value={projectData.repositoryPassword} onChange={handleChange} />
        </label>
        
        <div className="NewDep-form-container-editP-Grid"> </div>*/