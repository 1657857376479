import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginForm from './components/LoginForm';
import RegisterForm from './components/RegisterForm';
import ProjectFormNew from './components/ProjectFormWizard';
//import ProjectFormNew from './components/ProjectFormNew';
import NewFace from './components/Dashboard';
import PrivateRoute from './components/PrivateRoute';
import Profile from './components/GetProfile';
import ChangePassword from './components/ChangePassword';
import EditProjectForm from './components/EditProject'; 
import EditSecretsForm from './components/EditSecret'; 
import ResetPassword from './components/ResetPassword'; 
import ForgotPassword from './components/ForgotPassword';
import History from './components/History';
import './styles/dashboard.css';
import './styles/main.css';
import './styles/forms.css';
import './styles/buttons.css';
import './styles/layout.css';
import useAuthCheck from './services/tokenService';

function App() {
  return (
    <Router>
      <Routes>
        {/* Rutas públicas */}
        <Route path="/login" element={ <LoginForm /> } />
        <Route path="/register" element={<RegisterForm />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />

        {/* Rutas privadas */}
        <Route path="/newproject" element={
          <PrivateRoute>
            <AuthenticatedRoutesWrapper component={ProjectFormNew} />
          </PrivateRoute>
        } />
        <Route path="/homeNew" element={
          <PrivateRoute>
            <AuthenticatedRoutesWrapper component={NewFace} />
          </PrivateRoute>
        } />
        <Route path="/profile" element={
          <PrivateRoute>
            <AuthenticatedRoutesWrapper component={Profile} />
          </PrivateRoute>
        } />
        <Route path="/change-password" element={
          <PrivateRoute>
            <AuthenticatedRoutesWrapper component={ChangePassword} />
          </PrivateRoute>
        } />
        <Route path="/edit-project/:id" element={
          <PrivateRoute>
            <AuthenticatedRoutesWrapper component={EditProjectForm} />
          </PrivateRoute>
        } />
        <Route path="/edit-secrets/:id" element={
          <PrivateRoute>
            <AuthenticatedRoutesWrapper component={EditSecretsForm} />
          </PrivateRoute>
        } />
        <Route path="/project/:projectId/history" element={
          <PrivateRoute>
            <AuthenticatedRoutesWrapper component={History} />
          </PrivateRoute>
        } />

        {/* Ruta por defecto */}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
}

// Este componente envuelve las rutas que requieren autenticación para realizar la verificación del token
function AuthenticatedRoutesWrapper({ component: Component }) {
  useAuthCheck();
  return <Component />;
}


export default App;

/*function App() {
  useAuthCheck();
  return (
    <Router>
        <Routes>
          <Route path="/login" element={ <LoginForm /> } />
          <Route path="/register" element={<RegisterForm />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/newproject" element={
            <PrivateRoute>
              <ProjectFormNew />
            </PrivateRoute>
          } />
          <Route path="/homeNew" element={
            <PrivateRoute>
              <NewFace />
            </PrivateRoute>
          } />
          <Route path="/profile" element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          } />
          <Route path="/change-password" element={
            <PrivateRoute>
              <ChangePassword />
            </PrivateRoute>
          } />
          <Route path="/edit-project/:id" element={
            <PrivateRoute>
              <EditProjectForm />
            </PrivateRoute>
          } />
          <Route path="/edit-secrets/:id" element={
            <PrivateRoute>
              <EditSecretsForm />
            </PrivateRoute>
          } />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
    </Router>
  );
}*/