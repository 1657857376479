import React from 'react';
import '../styles/ConfirmationModal.css'; // Archivo CSS para estilizar el modal

const ConfirmationModal = ({ open, onClose, onConfirm, message, messageCompl }) => {
  if (!open) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <h2>Message</h2>
        <p>{message}</p>
        <p>{messageCompl}</p>
        <div className="modal-buttons">
          <button type="submit" onClick={onConfirm} style={{ width: '140px', height: '40px' }}>Confirm</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;