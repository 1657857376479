import React from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Select, MenuItem, ListItemText, ListItemIcon, FormControl} from '@mui/material';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import GOlogo from '../assets/Go-Logo_Blue.png';
import Javalogo from '../assets/java-logo.png';
import JavaScriptlogo from '../assets/JavaScript-logo.png';
import Pythonlogo from '../assets/Python.png';
import Csharplogo from '../assets/Csharp_Logo.png';
/*import Cpplogo from '../assets/cpp.png'
import Phplogo from '../assets/php.png';
import typescriptlogo from '../assets/typescript_logo.png';*/
import Nodelogo from '../assets/node-js.png';
/*import Rubylogo from '../assets/ruby-logo.png';
import Swiftlogo from '../assets/swift_logo.png';
import Kotlinlogo from '../assets/Kotlin_Icon.png';*/
import '../styles/step1.css';

const options = [
  { value: 'javascript', label: 'JavaScript', icon: JavaScriptlogo },
  { value: 'python', label: 'Python', icon: Pythonlogo },
  { value: 'java', label: 'Java', icon: Javalogo },
  { value: 'golang', label: 'Golang', icon: GOlogo },
  { value: 'nodejs', label: 'NodeJS', icon: Nodelogo },
  { value: 'csharp', label: 'C#', icon: Csharplogo },
  /*{ value: 'cpp', label: 'C++', icon: Cpplogo },
  { value: 'php', label: 'PHP', icon: Phplogo },
  { value: 'typescript', label: 'TypeScript', icon: typescriptlogo },
  { value: 'ruby', label: 'Ruby', icon: Rubylogo },
  { value: 'swift', label: 'Swift', icon: Swiftlogo },
  { value: 'kotlin', label: 'Kotlin', icon: Kotlinlogo },*/
  // Agrega más opciones aquí
];

const CustomOption = ({ data, ...props }) => (
  <MenuItem {...props} style={{ display: 'flex', alignItems: 'center' }}>
    {data.icon && (
      <ListItemIcon>
        <img src={data.icon} alt={data.label} style={{ width: '30px', height: '30px', marginRight: '10px' }} />
      </ListItemIcon>
    )}
    <ListItemText>{data.label}</ListItemText>
  </MenuItem>
);

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 224, // Ajusta esta altura según tus necesidades (4.5 * 48px item height)
      width: 150,
    },
  },
};

const Step1 = ({ formData, updateFormData, nextStep }) => {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: formData,
    enableReinitialize: true,
    onSubmit: (values) => {
      updateFormData(values);
      nextStep();
    },
  });

  return (
    <div className="main-container-step1">
      <form className="NewDep-form-container-step1" onSubmit={formik.handleSubmit}>
        <IconButton 
        onClick={() => navigate('/homeNew')} 
        style={{
          position: 'absolute',
          top: '80px',
          right: '20px',
          backgroundColor: '#f0f0f0', // Color tenue
          width: '30px', // Ancho y alto igual para hacerlo redondo
          height: '30px',
          padding: '5px', // Ajuste del padding
          borderRadius: '50%', // Asegura que sea redondo
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center',
          zIndex: 1000 // Asegura que esté encima de otros elementos
          }}
        >
          <CloseIcon style={{ fontSize: '16px', color: '#888' }} /> {/* Tamaño pequeño y color tenue */}
        </IconButton>
        <h2>New Deployment</h2>
        <h4 >All fields are required.</h4>
        <label>
          Deployment Name:
          <input
            type="text"
            name="projectName"
            onChange={formik.handleChange}
            value={formik.values.projectName}
            required
          />
        </label>
        <label>Programming Language:</label>
        <FormControl fullWidth>
          <Select
            labelId="language-select-label"
            id="language-select"
            name="programmingLanguage"
            value={formik.values.programmingLanguage}
            required
            onChange={formik.handleChange}
            sx={{ backgroundColor: 'white', width: '100%', height: '40px'}}
            MenuProps={MenuProps}
            renderValue={(selected) => {
              const selectedOption = options.find((option) => option.value === selected);
              return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {selectedOption && selectedOption.icon && selectedOption.value !== '' && (
                    <img src={selectedOption.icon} alt={selectedOption.label} style={{ width: '30px', height: '30px', marginRight: '10px' }} />
                  )}
                  {selectedOption ? selectedOption.label : '--Select Programming Language--'}
                </div>
              );
            }}
          >
            {options.map((option) => (
              <CustomOption key={option.value} data={option} value={option.value} />
            ))}
          </Select>
        </FormControl>
        <label>
          Programming Language Version:
          <input
            type="text"
            name="progLangVersion"
            onChange={formik.handleChange}
            value={formik.values.progLangVersion}
            required
          />
        </label>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '60px' }}>
          <div>
            <button type="button" onClick={() => window.location.href = '/homeNew'}>
              Cancel
            </button>
          </div>
          <div>
            <button type="submit">
              Next
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Step1;

/*<div>
          <h4>Current Values:</h4>
          <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', maxHeight: '100px', overflowY: 'scroll', padding: '10px', border: '1px solid #ccc', borderRadius: '4px' }}>
            {JSON.stringify(formik.values, null, 2)}
          </pre>
        </div>*/