import React from 'react';
import { useFormik } from 'formik';
import { Select, MenuItem, ListItemText, ListItemIcon, FormControl, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import AWSEC2logo from '../assets/aws_ec2_logo.png';
import AWSLambdalogo from '../assets/aws_lambda_logo.png';
import AWSEKSlogo from '../assets/aws_eks.png';
//import AWSFarlogo from '../assets/aws_fargate.png';
//import AWSEBTlogo from '../assets/aws_ebt_logo.png';

import AzAKSlogo from '../assets/az_aks_logo.png';
import AzApSrlogo from '../assets/az_appServ_logo.png';
//import AzContlogo from '../assets/az_container_logo.png';
//import AzFunclogo from '../assets/az_funct_logo.png';
import AzVMslogo from '../assets/az_vms_logo.png';

import GCPAppEnglogo from '../assets/GCP_AppEngine.png';
//import GCPCFunctlogo from '../assets/GCP_CloudFunctions.png';
import GCPCRun from '../assets/GCP_CloudRun.png';
import GCPCompEng from '../assets/GCP_Compute_Engine.png';
import GCPGKE from '../assets/GCP_GKE.png';

import '../styles/step4.css';

const optionsAWS = [
  { value: 'eks', label: 'EKS (Elastic Kubernetes Service)', icon: AWSEKSlogo },
  { value: 'lambda', label: 'Lambda', icon: AWSLambdalogo },
  { value: 'ec2', label: 'EC2', icon: AWSEC2logo },
  //{ value: 'fargate', label: 'Fargate (Serverless)', icon: AWSFarlogo },
  //{ value: 'elasticBeanstalk', label: 'Elastic Beanstalk (PaaS)', icon: AWSEBTlogo },
  // Agrega más opciones aquí
];
  
  const optionsAZ = [
    { value: 'aks', label: 'Azure Kubernetes Service)', icon: AzAKSlogo },
    { value: 'appService', label: 'App Service', icon: AzApSrlogo },
    { value: 'vm', label: 'Virtual Machines', icon: AzVMslogo },
    //{ value: 'functions', label: 'Functions', icon: AzFunclogo },
    //{ value: 'containerInstances', label: 'Container Instances (Serverless)', icon: AzContlogo },
    // Agrega más opciones aquí
  ];
  
  const optionsGCP = [
    { value: 'gke', label: 'Kubernetes Engine', icon: GCPGKE },
    { value: 'appEngine', label: 'App Engine', icon: GCPAppEnglogo },
    { value: 'computeEngine', label: 'Compute Engine', icon: GCPCompEng },
    //{ value: 'cloudFunctions', label: 'Cloud Functions', icon: GCPCFunctlogo },
    { value: 'cloudRun', label: 'Cloud Run', icon: GCPCRun },
    // Agrega más opciones aquí
  ];

const CustomOption = ({ data, ...props }) => (
    <MenuItem {...props} style={{ display: 'flex', alignItems: 'center' }}>
      {data.icon && (
        <ListItemIcon>
          <img src={data.icon} alt={data.label} style={{ width: '30px', height: '30px', marginRight: '10px' }} />
        </ListItemIcon>
      )}
      <ListItemText>{data.label}</ListItemText>
    </MenuItem>
);

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 224, // Ajusta esta altura según tus necesidades (4.5 * 48px item height)
      width: 150,
    },
  },
};

const Step4 = ({ formData, updateFormData, handleSubmit, previousStep }) => {
  const navigate = useNavigate();
  const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const formik = useFormik({
      initialValues: formData,
      enableReinitialize: true,
      onSubmit: async values => {
        await updateFormData(values);
        handleSubmit(values);
      },
    });
  
    const handlePrevious = () => {
        updateFormData(formik.values);
        previousStep();
    };
    
    return (
        <div className="main-container-step4">
        <form className="NewDep-form-container-step4" onSubmit={formik.handleSubmit}>
          <IconButton 
          onClick={() => navigate('/homeNew')} 
          style={{
            position: 'absolute',
            top: '80px',
            right: '20px',
            backgroundColor: '#f0f0f0', // Color tenue
            width: '30px', // Ancho y alto igual para hacerlo redondo
            height: '30px',
            padding: '5px', // Ajuste del padding
            borderRadius: '50%', // Asegura que sea redondo
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            zIndex: 1000 // Asegura que esté encima de otros elementos
            }}
          >
            <CloseIcon style={{ fontSize: '16px', color: '#888' }} /> {/* Tamaño pequeño y color tenue */}
          </IconButton>
          <h2 style={{marginTop: isMobile ? '25%' : '0%' }}>New Deployment</h2>
          <h4>All fields are required.</h4>
          {formik.values.deploymentTarget.cloudProvider && (
            <>
              {formik.values.deploymentTarget.cloudProvider === 'AWS' && (
                <>
                  <FormControl fullWidth>
                    <label style={{marginLeft: isMobile ? '0%' : '32.5%' }}>
                      Compute Services:
                    </label>
                    <Select
                      labelId="aws-select-label"
                      id="aws-select"
                      name="deploymentTarget.computeComponent"
                      value={formik.values.deploymentTarget.computeComponent}
                      onChange={formik.handleChange}
                      required
                      sx={{ backgroundColor: 'white', width: isMobile ? '100%' : '35%', height: '40px', marginLeft: isMobile ? '0%' : '32.5%' }}
                      MenuProps={MenuProps}
                      renderValue={(selected) => {
                        const selectedOption = optionsAWS.find(option => option.value === selected);
                        return (
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            {selectedOption && selectedOption.icon && selectedOption.value !== '' && (
                              <img src={selectedOption.icon} alt={selectedOption.label} style={{ width: '30px', height: '30px', marginRight: '10px' }} />
                            )}
                            {selectedOption ? selectedOption.label : '--Select Compute Services--'}
                          </div>
                        );
                      }}
                    >
                      {optionsAWS.map(option => (
                        <CustomOption key={option.value} data={option} value={option.value} />
                      ))}
                    </Select>
                  </FormControl>
                  {formik.values.deploymentTarget.cloudProvider === 'AWS' && formik.values.deploymentTarget.computeComponent === 'ec2' && (
                    <div className="NewDep-form-container-step4-Grid">
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                          <h4>Development Env.</h4>
                          <label>
                            Component Name:
                            <input type="text" required name="deploymentTarget.computeCompDev.componentName" onChange={formik.handleChange} value={formik.values.deploymentTarget.computeCompDev.componentName} />
                          </label>
                          <label>
                            Imagen ID:
                            <input type="text" required name="deploymentTarget.computeCompDev.componentImagId" onChange={formik.handleChange} value={formik.values.deploymentTarget.computeCompDev.componentImagId} />
                          </label>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <h4>Quality Env.</h4>
                          <label>
                            Component Name:
                            <input type="text" required name="deploymentTarget.computeCompQa.componentName" onChange={formik.handleChange} value={formik.values.deploymentTarget.computeCompQa.componentName} />
                          </label>
                          <label>
                            Imagen ID:
                            <input type="text" required name="deploymentTarget.computeCompQa.componentImagId" onChange={formik.handleChange} value={formik.values.deploymentTarget.computeCompQa.componentImagId} />
                          </label>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <h4>Production Env.</h4>
                          <label>
                            Component Name:
                            <input type="text" required name="deploymentTarget.computeCompPrd.componentName" onChange={formik.handleChange} value={formik.values.deploymentTarget.computeCompPrd.componentName} />
                          </label>
                          <label>
                            Imagen ID:
                            <input type="text" required name="deploymentTarget.computeCompPrd.componentImagId" onChange={formik.handleChange} value={formik.values.deploymentTarget.computeCompPrd.componentImagId} />
                          </label>
                        </Grid>
                      </Grid>
                    </div>
                  )}
                </>
              )}
      
              {formik.values.deploymentTarget.cloudProvider === 'AZURE' && (
                <>
                  <label style={{marginLeft: isMobile ? '0%' : '32.5%' }}>
                    Compute Services:
                  </label>
                  <FormControl fullWidth>
                    <Select
                      labelId="az-select-label"
                      id="az-select"
                      name="deploymentTarget.computeComponent"
                      value={formik.values.deploymentTarget.computeComponent}
                      onChange={formik.handleChange}
                      required
                      sx={{ backgroundColor: 'white', width: isMobile ? '100%' : '35%', height: '40px', marginLeft: isMobile ? '0%' : '32.5%' }}
                      MenuProps={MenuProps}
                      renderValue={(selected) => {
                        const selectedOption = optionsAZ.find(option => option.value === selected);
                        return (
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            {selectedOption && selectedOption.icon && selectedOption.value !== '' && (
                              <img src={selectedOption.icon} alt={selectedOption.label} style={{ width: '30px', height: '30px', marginRight: '10px' }} />
                            )}
                            {selectedOption ? selectedOption.label : '--Select Compute Services--'}
                          </div>
                        );
                      }}
                    >
                      {optionsAZ.map(option => (
                        <CustomOption key={option.value} data={option} value={option.value} />
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}
              {formik.values.deploymentTarget.cloudProvider === 'GCP' && (
                <>
                  <label style={{marginLeft: isMobile ? '0%' : '32.5%' }}>
                    Compute Services:
                  </label>
                  <FormControl fullWidth>
                    <Select
                      labelId="gcp-select-label"
                      id="gcp-select"
                      name="deploymentTarget.computeComponent"
                      value={formik.values.deploymentTarget.computeComponent}
                      onChange={formik.handleChange}
                      required
                      sx={{ backgroundColor: 'white', width: isMobile ? '100%' : '35%', height: '40px', marginLeft: isMobile ? '0%' : '32.5%' }}
                      MenuProps={MenuProps}
                      renderValue={(selected) => {
                        const selectedOption = optionsGCP.find(option => option.value === selected);
                        return (
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            {selectedOption && selectedOption.icon && selectedOption.value !== '' && (
                              <img src={selectedOption.icon} alt={selectedOption.label} style={{ width: '30px', height: '30px', marginRight: '10px' }} />
                            )}
                            {selectedOption ? selectedOption.label : '--Select Compute Services--'}
                          </div>
                        );
                      }}
                    >
                      {optionsGCP.map(option => (
                        <CustomOption key={option.value} data={option} value={option.value} />
                      ))}
                    </Select>
                  </FormControl>
                  {formik.values.deploymentTarget.cloudProvider === 'GCP' && formik.values.deploymentTarget.computeComponent === 'cloudRun' && (
                    <div className="NewDep-form-container-step4-Grid">
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                          <h4>Development Env.</h4>
                          <label>
                            GCP Project ID:
                            <input type="text" required name="deploymentTarget.computeCompDev.gcpProjectId" onChange={formik.handleChange} value={formik.values.deploymentTarget.computeCompDev.gcpProjectID} />
                          </label>
                          <label>
                            Cloud Run Region:
                            <input type="text" required name="deploymentTarget.computeCompDev.cloudrunRegion" onChange={formik.handleChange} value={formik.values.deploymentTarget.computeCompDev.cloudrunRegion} />
                          </label>
                          <label>
                            Cloud Run Service Name:
                            <input type="text" required name="deploymentTarget.computeCompDev.cloudrunServiceName" onChange={formik.handleChange} value={formik.values.deploymentTarget.computeCompDev.cloudrunServiceName} />
                          </label>
                          <label>
                            Artifact Registry Repository:
                            <input type="text" required name="deploymentTarget.computeCompDev.artifactRegistryRepository" onChange={formik.handleChange} value={formik.values.deploymentTarget.computeCompDev.artifactRegistryRepository} />
                          </label>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <h4>Quality Env.</h4>
                          <label>
                            GCP Project ID:
                            <input type="text" required name="deploymentTarget.computeCompQa.gcpProjectId" onChange={formik.handleChange} value={formik.values.deploymentTarget.computeCompQa.gcpProjectID} />
                          </label>
                          <label>
                            Cloud Run Region:
                            <input type="text" required name="deploymentTarget.computeCompQa.cloudrunRegion" onChange={formik.handleChange} value={formik.values.deploymentTarget.computeCompQa.cloudrunRegion} />
                          </label>
                          <label>
                            Cloud Run Service Name:
                            <input type="text" required name="deploymentTarget.computeCompQa.cloudrunServiceName" onChange={formik.handleChange} value={formik.values.deploymentTarget.computeCompQa.cloudrunServiceName} />
                          </label>
                          <label>
                            Artifact Registry Repository:
                            <input type="text" required name="deploymentTarget.computeCompQa.artifactRegistryRepository" onChange={formik.handleChange} value={formik.values.deploymentTarget.computeCompQa.artifactRegistryRepository} />
                          </label>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <h4>Production Env.</h4>
                          <label>
                            GCP Project ID:
                            <input type="text" required name="deploymentTarget.computeCompPrd.gcpProjectId" onChange={formik.handleChange} value={formik.values.deploymentTarget.computeCompPrd.gcpProjectID} />
                          </label>
                          <label>
                            Cloud Run Region:
                            <input type="text" required name="deploymentTarget.computeCompPrd.cloudrunRegion" onChange={formik.handleChange} value={formik.values.deploymentTarget.computeCompPrd.cloudrunRegion} />
                          </label>
                          <label>
                            Cloud Run Service Name:
                            <input type="text" required name="deploymentTarget.computeCompPrd.cloudrunServiceName" onChange={formik.handleChange} value={formik.values.deploymentTarget.computeCompPrd.cloudrunServiceName} />
                          </label>
                          <label>
                            Artifact Registry Repository:
                            <input type="text" required name="deploymentTarget.computeCompPrd.artifactRegistryRepository" onChange={formik.handleChange} value={formik.values.deploymentTarget.computeCompPrd.artifactRegistryRepository} />
                          </label>
                        </Grid>
                      </Grid>
                    </div>
                  )}
                </>
              )}
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '60px', width: isMobile ? '100%' : '94.3%', marginLeft: isMobile ? '0%' : '2.5%' }}>
                  <button type="button" onClick={handlePrevious}>Previous</button>
                  <button type="submit">Finish</button>
              </div>
            </>
          )} 
        </form>
      </div>
    );
  };

  export default Step4;

  /*<div>
            <h4>Current Values:</h4>
            <pre style={{
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
              maxHeight: '100px',
              overflowY: 'scroll',
              padding: '10px',
              border: '1px solid #ccc',
              borderRadius: '4px'
            }}>{JSON.stringify(formik.values, null, 2)}</pre>
          </div>*/