import React, { useState, useEffect }  from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import NavBar from './NavBar';
import ConfirmationChangePassModal from './MessageModal';
import { encryptData, fetchPublicKey} from '../services/cryptoService';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [publicKey, setPublicKey] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [messageCompl, setMessageCompl] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setMessage(null);
  };

  // Obtener la clave pública al montar el componente
  useEffect(() => {
    const loadPublicKey = async () => {
      try {
        const key = await fetchPublicKey();
        setPublicKey(key);
      } catch (error) {
        console.error('Error fetching public key:', error);
      }
    };
    loadPublicKey();
  }, []);

  const handleConfirmChangePass = async () => {
      try {
        handleCloseDialog();
        if (message === 'Success:') {
          navigate('/homeNew');
        }
      } catch (error) {
        console.error('Error ForgotPassword:', error);
      }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setMessage('Error:');
      setMessageCompl('Passwords do not match.');
      setOpenDialog(true);
      return;
    }

    try {
      const token = localStorage.getItem('token');
      // Encriptar el username y el password antes de enviarlos
      const encryptedoldPassword = await encryptData(oldPassword, publicKey);
      const encryptednewPassword = await encryptData(newPassword, publicKey);
      const response =  await axios.post('https://doaas-backend-q52lot4npq-uc.a.run.app/api/auth/change-password', {
        oldPassword: encryptedoldPassword,
        newPassword: encryptednewPassword
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setMessage('Success:');
      setMessageCompl('Password changed successfully.');
      setOpenDialog(true);
    } catch (error) {
      // Verificar si el error tiene una respuesta del servidor
      if (error.response && error.response.status === 400) {
        setMessage('Error:');
        setMessageCompl(error.response.data.message || 'Error changing password.');
      } else {
        setMessage('Error:');
        setMessageCompl('An unexpected error occurred.');
      }
      setOpenDialog(true);
    }
  };
//change-password-container
  return (
    <div style={{ display: 'flex', height: '100vh', flexDirection: 'column' }}>
      <CssBaseline />
      <div style={{ display: 'flex', flex: 1, overflow: 'hidden', maxHeight: '70px' }}>
        <NavBar />
      </div>
        <div className="main-container-step1">
          <form className="NewDep-form-container-step1" onSubmit={handleChangePassword}>
            <IconButton 
              onClick={() => navigate('/homeNew')} 
              style={{
                position: 'absolute',
                top: '80px',
                right: '20px',
                backgroundColor: '#f0f0f0', // Color tenue
                width: '30px', // Ancho y alto igual para hacerlo redondo
                height: '30px',
                padding: '5px', // Ajuste del padding
                borderRadius: '50%', // Asegura que sea redondo
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                zIndex: 1000 // Asegura que esté encima de otros elementos
              }}
            >
              <CloseIcon style={{ fontSize: '16px', color: '#888' }} /> {/* Tamaño pequeño y color tenue */}
            </IconButton>
            <h2>Change Password</h2>
            <div>
              <label>Old Password:</label>
              <input
                type="password"
                value={oldPassword}
                placeholder="Old Password"
                onChange={(e) => setOldPassword(e.target.value)}
                required
              />
            </div>
            <div>
              <label>New Password:</label>
              <input
                type="password"
                value={newPassword}
                placeholder="New Password"
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </div>
            <div>
              <label>Confirm New Password:</label>
              <input
                type="password"
                value={confirmPassword}
                placeholder="Confirm New Password"
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            <ConfirmationChangePassModal
              open={openDialog}
              onClose={handleCloseDialog}
              onConfirm={handleConfirmChangePass}
              message={message}
              messageCompl={messageCompl}
            />
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px', width: '94.3%', marginLeft: '2.5%' }}>
              <button type="submit" style={{width: '160px', height: '40px'}}>Change Password</button>
            </div>
            
          </form>
          
        </div>
    </div>
  );
};

export default ChangePassword;

/*width: isMobile ? '100%' : '94.3%', marginLeft: isMobile ? '0%' : '2.5%'*/